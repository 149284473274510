import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {

  constructor() { }

  getErrorMessage(response: any) {
    console.log({response});
    if (response.status === 400 || response.status === 401) {
      return response?.error?.message;
    } else if (response.status === 422) {
      const errorArr: any = [];
      const validation_errors = response.error.errors;
      console.log({validation_errors})
      validation_errors?.forEach((error: any) => {
        errorArr.push(error?.message);
      });
      return errorArr;
    }
    return 'Unknown error occured, Please try again.';
  }
}
