import { MetaReducer } from '@ngrx/store';
import * as fromAuth from './auth.reducer';
import { routerReducer } from '@ngrx/router-store';
import { AppState } from '../app.state';
import { environment } from 'src/environments/environment';
import * as fromShared from './shared.reducer';
import * as fromAccount from './account.reducer';
import * as fromAddress from './address.reducer';
import * as fromImage from './image.reducer';

export const reducers = {
  auth: fromAuth.reducer,
  shared: fromShared.reducer,
  router: routerReducer,
  account: fromAccount.reducer,
  address: fromAddress.reducer,
  image: fromImage.reducer,
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production
  ? []
  : [];
