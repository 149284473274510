import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from './store/app.state';
import { autoLogout } from './store/actions/auth.actions';
import { getUserSession } from 'src/app/store/selectors/auth.selectors';
import { Platform } from '@angular/cdk/platform';
import * as fromAuthActions from '../../src/app/store/actions/auth.actions'
import { getLoading } from './store/selectors/shared.selector';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'lto-delivery-admin';
  showLoading$!: Observable<boolean>;

  clicked: any;

  userSession$: Observable<any>;
  userSession = null;

  constructor(
    private store: Store<AppState>,
    private platform: Platform,
    //Sample Staking
  ) {
    this.userSession$ = this.store.select(getUserSession);
    this.showLoading$ = this.store.select(getLoading);
    this.initializeApp();
    // this.store.dispatch(autoLogout());
  }

  initializeApp() {
    this.userSession$.pipe().subscribe((userSession: any) => {
      console.log({userSession});
      this.userSession = userSession;
      // this.setUpDeepLink();
    });
    
    this.store.dispatch(fromAuthActions.autoLogin());

    window.addEventListener('auth:timeout', (e: any) => {
      console.log('auth:timeout', e?.detail);
      const isTimeout = e?.detail;
      if (isTimeout && this.userSession) {
        this.logout();
      }
    });
  }

  logout(){
    this.store.dispatch(autoLogout());
  }
}
