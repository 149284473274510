import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { timeout, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoadingSpinnerComponent } from '../shared/modals/loading-spinner/loading-spinner.component';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(
    private http: HttpClient,
    private bsModalService: BsModalService
  ) { }

  private executeFilter = new Subject<void>();
  executeFilter$ = this.executeFilter.asObservable();

  modalSpinner: any;

  executeFilterFunction(): void {
    this.executeFilter.next()
  }

  getWelcomeContent(): Observable<any> {
    return this.http
      .get<any>(environment.apiUrl + `/v2/public/welcome-content`);
  }

  dataURLtoBlob(dataurl: any) { 
    if(dataurl){
      const split = dataurl.split(',');
      const type = split[0].replace('data:', '').replace(';base64', '');
      console.log({type});
      const byteString = atob(split[1]);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i += 1) {
          ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], {type});
    }

    return
  }

  formatGETId(membershipKey = 0, userId = 0) {

    const padLeft = (numb: number, size: number) => {
      let s = numb + '';
      while (s.length < size) { s = '0' + s; }
      return s;
    }

    const a = padLeft(membershipKey, 4);
    const formatedUserId = padLeft(userId, 8);

    const b = formatedUserId.substring(0, 4);
    const c = formatedUserId.substring(4, 8);

    return a + '-' + b + '-' + c;

  }

  async dataURLtoNewFile(dataURI: any, format: any) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeString });
    return new File([blob], `qrcode.${format}`);
}

  getUnreadNotification(): Observable<any> {
    return this.http
      .get<any>(environment.apiUrl + '/v2/account/user-notifications');
  }

  readNotification(item: any): Observable<any> {
    return this.http
      .post<any>(environment.apiUrl + '/v2/account/read-notification', JSON.stringify(item));
  }

  getEventsList(){
    return this.http
    .post<any>(environment.apiUrl + '/api/rider/appointments', '');
  }

  openSpinner(status: any){
    if(status){
      const config: any = {
        backdrop: true,
        ignoreBackdropClick: true,
        class: 'modal-dialog-centered spinner-modal'
      }
  
      this.bsModalService.onHidden.subscribe((data) => {
        console.log('hidden');
      })
  
      this.bsModalService.show(LoadingSpinnerComponent, config);
    }
    else{
      this.bsModalService.hide();
    }
  }
}
