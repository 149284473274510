/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';

export enum ActionTypes {
  SET_LOADING_SPINNER = '[Shared] set loading spinner',
  SET_ERROR_MESSAGE = '[Shared] set error message',
  SET_WELCOME_CONTENT = '[Shared] set welcome content',
  FAILED_WELCOME_CONTENT = '[Shared] Failed welcome content',
  FAILED_TRANSACTION = '[Shared] Failed transaction',
  SET_FCM_TOKEN = '[Shared] Set FCM Token',
  GET_UNREAD_NOTIFICATIONS = '[Shared] Get Unread Notifications',
  GET_UNREAD_NOTIFICATIONS_SUCCESS = '[Shared] Get Unread Notifications Success',
  GET_USER_EVENTS = '[Shared] Get User Event',
  GET_USER_EVENTS_SUCCESS = '[Shared] Get User Event Success',
  RELOAD_USER_EVENTS = '[Shared] Reload User Event',
  REORDER_DASHBOARD = '[Shared] Reorder Dashboard',
  REORDER_DASHBOARD_SUCCESS = '[Shared] Reorder Dashboard Success',
}

export const setLoadingSpinner = createAction(
  ActionTypes.SET_LOADING_SPINNER,
  props<{ status: Boolean }>()
);

export const setErrorMessage = createAction(
  ActionTypes.SET_ERROR_MESSAGE,
  props<{ message: string }>()
);

export const setWelcomeContent = createAction(
  ActionTypes.SET_WELCOME_CONTENT,
  props<{ data: any }>()
);

export const failedWelcomeContent = createAction(
  ActionTypes.FAILED_WELCOME_CONTENT
);

export const failedTransaction = createAction(
  ActionTypes.FAILED_TRANSACTION,
  props<{ data: any }>()
);

export const setFCMToken = createAction(
  ActionTypes.SET_FCM_TOKEN,
  props<{ data: any }>()
);

export const getUnreadNotification = createAction(
  ActionTypes.GET_UNREAD_NOTIFICATIONS
);

export const getUnreadNotificationSuccess = createAction(
  ActionTypes.GET_UNREAD_NOTIFICATIONS_SUCCESS,
  props<{ data: any }>()
);

export const getUserEvents = createAction(
  ActionTypes.GET_USER_EVENTS
);

export const getUserEventsSucces = createAction(
  ActionTypes.GET_USER_EVENTS_SUCCESS,
  props<{ data: any }>()
);

export const reloadUserEvents = createAction(
  ActionTypes.RELOAD_USER_EVENTS
);

export const reorderDashboard = createAction(
  ActionTypes.REORDER_DASHBOARD,
  props<{ data: any }>()
);

export const reorderDashboardSuccess = createAction(
  ActionTypes.REORDER_DASHBOARD_SUCCESS,
  props<{ data: any }>()
);
