import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AccountService } from 'src/app/services/account.service';
import { ModalService } from 'src/app/services/modal.service';
import { createRiderTerritories, createTerritories, createTerritoryArea, getRoles, getSmsStatus, getUsers, updateRiderTerritories, updateTerritoryArea, updateUserTerritories } from 'src/app/store/actions/account.actions';
import { logIn } from 'src/app/store/actions/auth.actions';
import { setLoadingSpinner } from 'src/app/store/actions/shared.actions';
import { AppState } from 'src/app/store/app.state';
import { MustMatch } from 'src/app/validators/mustmatch.validator';

@Component({
  selector: 'app-create-territory-area',
  templateUrl: './create-territory-area.component.html',
  styleUrls: ['./create-territory-area.component.scss']
})
export class CreateTerritoryAreaComponent implements OnInit {

  territory!: any;

  territoryArea!: any;

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private accountService: AccountService,
    private bsModalService: BsModalService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      areaType: ['', [Validators.required]],
      areaStatus: ['', [Validators.required]],
      areaValue: ['', [Validators.required]],
    });
  }

  createTerritoryArea(){
    const payload = {
      areaType: this.areaType?.value,
      areaStatus: this.areaStatus?.value,
      areaValue: this.areaValue?.value
    }

    console.log({payload})

    this.modalService.showLoadingSpinner();
    this.store.dispatch(createTerritoryArea({data: payload, territory: this.territory}));
  }

  cancel(){
    this.modalService.hideCreateTerritoryArea();
  }

  get areaType(){
    return this.form.get('areaType');
  }

  get areaStatus(){
    return this.form.get('areaStatus');
  }

  get areaValue(){
    return this.form.get('areaValue');
  }
}
