import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AccountService } from 'src/app/services/account.service';
import { ModalService } from 'src/app/services/modal.service';
import { SharedService } from 'src/app/services/shared.service';
import { getAssignedDeliveries, getDeliveries, getRoles, getUnassignedDeliveries } from 'src/app/store/actions/account.actions';
import { setLoadingSpinner } from 'src/app/store/actions/shared.actions';
import { AppState } from 'src/app/store/app.state';

@Component({
  selector: 'app-assign-deliveries',
  templateUrl: './assign-deliveries.component.html',
  styleUrls: ['./assign-deliveries.component.scss']
})
export class AssignDeliveriesComponent implements OnInit {

  riders!: any;
  details!: any;

  rider: any;

  isReAssign!: any

  constructor(
    private store: Store<AppState>,
    private accountService: AccountService,
    private bsModalService: BsModalService,
    private sharedService: SharedService,
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
    console.log('details', this.riders)
  }

  cancel(){
    this.modalService.hideAssignDeliveries();
  }

  proceed(){
    const payload = {
      fromRiderUsername: this.details?.username,
      deliveryIds: this.details?.delivery_ids,
      toRiderUsername: this.rider?.username
    };

    console.log({payload});

    this.modalService.showLoadingSpinner();
    this.accountService.assignDeliveries(payload).toPromise().then((res) => {
      if(res){
        console.log({res});
        this.modalService.hideLoadingSpinner();
        this.modalService.hideAssignDeliveries();
        if(this.isReAssign){
          this.store.dispatch(getAssignedDeliveries());
        } else{
          this.store.dispatch(getUnassignedDeliveries());
        }
        this.modalService.successModal(`Successfully assigned deliveries to ${this.rider?.riderName}`);
      }
    }).catch((err) => {
      console.log({err});
      this.modalService.hideLoadingSpinner();
      this.modalService.errorModal(err?.error?.message);
    })
  }
}
