<div class="d-flex justify-content-between">
  <a class="d-none d-lg-block navbar-brand col-md-3 col-lg-2 me-0 px-3" href="/">
    <img src="assets/img/abyan.png" alt="">
    <!----------insert logo here-------------->
  </a>
</div>

<div>
  <a class="d-lg-none d-md-inline-block navbar-toggler">
    <svg-icon src="assets/custom/svg/menu-bar.svg" [svgStyle]="{ 'margin-right.px': 16 }" data-bs-toggle="offcanvas" data-bs-target="#offcanvasAbyanMobile" aria-controls="offcanvasAbyanMobile"></svg-icon> 
    <img src="assets/img/abyan.png" alt="">
  </a>
</div>

  