<nav class="navbar navbar-expand-lg navbar-dark" id="header">
    <div class="container-fluid">
      <!-- <app-authorised-side-nav-toggler></app-authorised-side-nav-toggler> -->
      <app-home-side-nav-toggler></app-home-side-nav-toggler>
      <div class="collapse navbar-collapse">
        <!-- <div class="form-group has-search">
          <span class="fa fa-search form-control-feedback"></span>
          <input type="text" class="search-nft form-control" placeholder="Search for Creators or NFT...">
        </div> -->
        <ul class="navbar-nav me-auto mb-2 mb-md-0">
          <li class="nav-item">
            <a href="/home/dashboard" routerLink="/home/dashboard" routerLinkActive="active" class="nav-link">Dashboard</a>
          </li>
          <li class="nav-item">
            <a href="/home/deliveries" routerLink="/home/deliveries" routerLinkActive="active" class="nav-link">Deliveries</a>
          </li>
          <li class="nav-item">
            <a href="/home/unassigned-deliveries" routerLink="/home/unassigned-deliveries" routerLinkActive="active" class="nav-link">Unassigned</a>
          </li>
          <li class="nav-item">
            <a href="/home/riders" routerLink="/home/riders" routerLinkActive="active" class="nav-link">Riders</a>
          </li>
          <li class="nav-item">
            <a href="/home/users" routerLink="/home/users" routerLinkActive="active" class="nav-link">Users</a>
          </li>
          <ng-container *ngIf="userProfile?.roles?.length > 0 && userProfile?.roles[0]?.name == 'super-administrator'">
            <li class="nav-item">
              <a href="/home/roles" routerLink="/home/roles" routerLinkActive="active" class="nav-link">Roles</a>
            </li>
          </ng-container>
          <li class="nav-item">
            <!-- <button type="button" class="btn btn-block btn-connected-wallet" (click)="logout()">Others</button> -->
            <div class="dropdown">
              <button class="btn btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false" (click)="logout()">
                {{ inputText }}
              </button>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item">Logout</a></li>
                <!-- <li><a class="dropdown-item">Another action</a></li>
                <li><a class="dropdown-item">Something else here</a></li> -->
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>