import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-side-nav-toggler',
  templateUrl: './home-side-nav-toggler.component.html',
  styleUrls: ['./home-side-nav-toggler.component.scss']
})
export class HomeSideNavTogglerComponent implements OnInit {

  @Input() title: any;

  constructor() { }

  ngOnInit(): void {
  }

}
