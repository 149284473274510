import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';
var moment = require('moment/moment');

@Component({
  selector: 'app-delivery-list',
  templateUrl: './delivery-list.component.html',
  styleUrls: ['./delivery-list.component.scss']
})
export class DeliveryListComponent implements OnInit {

  deliveries!: any;

  constructor(
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
    console.log(this.deliveries);
  }

  formatDate(date: any){
    if(date){
      return moment(new Date(date)).format('MMMM DD, YYYY');
    }
  }

  close(){
    this.modalService.hideDeliveryList();
  }

}
