import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StorageKeys {
    activeOrganization = 'activeOrganization';
    welcomeContent = 'welcomeContent';
    biometric = 'biometric';
}
