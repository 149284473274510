import { ChangeDetectorRef, Injectable } from '@angular/core';
import { act, Actions, createEffect, ofType } from '@ngrx/effects';
import {
  map,
  catchError,
  tap,
  mergeMap,
  withLatestFrom,
  exhaustMap,
} from 'rxjs/operators';
import { of } from 'rxjs';
import * as fromAuthActions from '../actions/auth.actions';
import * as fromAccountActions from '../actions/account.actions';
import * as fromOrganizationActions from '../actions/organization.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { AuthService } from '../../services/auth.service';
import { AlertService } from 'src/app/services/alert.service';
import { setLoadingSpinner } from '../actions/shared.actions';
import { ErrorService } from 'src/app/services/error.service';
import { getUserProfile } from '../actions/account.actions';
import { OfflineStorageService } from 'src/app/services/offline-storage.service';
import { NavigationExtras, Router } from '@angular/router';
import { getFCMToken } from '../selectors/shared.selector';
import { StorageKeys } from 'src/app/utils/storage-keys';
import { AccountService } from 'src/app/services/account.service';
import { ModalService } from 'src/app/services/modal.service';

@Injectable()
export class AuthEffects {

  logIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.logIn),
      exhaustMap((action) =>
        this.authService.logIn(action?.data).pipe(
          map((response: any) => {
            console.log('logIn$', response);
            const token = response?.details;
            this.modalService.hideLoadingSpinner();
            const user = this.authService.formatUser(token);
            console.log({user});
            this.authService.setUserSession(user);
            console.log({user});
            return fromAuthActions.logInSuccess({ data: user, redirect: true });
          }),
          catchError((response) => {
            console.error('logIn$', response);
            this.modalService.hideLoadingSpinner();
            this.modalService.errorModal(response?.error?.message);
            const error = {
              status: response.status,
              error: this.errorService.getErrorMessage(response)
            };
            return of(fromAuthActions.loginFailed({ data: error, payload: action}));
          })
        )
      )
    )
  );

  loginRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAuthActions.logInSuccess),
        tap(async (action) => {
          console.log('loginRedirect$', action);
          this.router.navigateByUrl('/home')
        })
      ),
    { dispatch: false }
  );

  loginFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAuthActions.loginFailed),
        tap(async (action) => {
          console.log('loginFailed$', action);
        })
      ),
    { dispatch: false }
  );

  autoLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.autoLogin),
      mergeMap(async (action) => {
        const user = await this.authService.getUserSession();
        if(user){
          console.log({userEffects: user})
          return fromAuthActions.logInSuccess({ data: user, redirect: user ? true : false });
        } else{
          return fromAuthActions.loginFailed({data: '', payload: ''})
        }
      })
    ) 
  );

  logOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAuthActions.autoLogout),
        tap(() => {
          this.authService.logout();
          this.router.navigateByUrl('/')
        })
      ),
    { dispatch: false }
  );

  signUp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.signUp),
      exhaustMap((action) =>
        this.authService.register(action?.data).pipe(
          map((response) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            console.log('signUp$', response);
            return fromAuthActions.signUpSuccess({ data: response?.details });
          }),
          catchError((response) => {
            console.error('signUp$', response);
            this.store.dispatch(setLoadingSpinner({ status: false }));
            const error = {
              status: response.status,
              error: this.errorService.getErrorMessage(response)
            };
            console.log(error);
            return of(fromAuthActions.signUpFailed({ data: error }));
          })
        )
      )
    )
  );

  signUpSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAuthActions.signUpSuccess),
        tap(async (action) => {
          console.log('signUpSuccess$', action?.data);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private store: Store<AppState>,
    private alertService: AlertService,
    private errorService: ErrorService,
    private storage: OfflineStorageService,
    private offlineStorageService: OfflineStorageService,
    private storageKeys: StorageKeys,
    private accountService: AccountService,
    private router: Router,
    private modalService: ModalService
  ) {}
}
