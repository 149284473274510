import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AccountService } from 'src/app/services/account.service';
import { ModalService } from 'src/app/services/modal.service';
import { SharedService } from 'src/app/services/shared.service';
import { getRoles, getUsers } from 'src/app/store/actions/account.actions';
import { setLoadingSpinner } from 'src/app/store/actions/shared.actions';
import { AppState } from 'src/app/store/app.state';

@Component({
  selector: 'app-assign-role',
  templateUrl: './assign-role.component.html',
  styleUrls: ['./assign-role.component.scss']
})
export class AssignRoleComponent implements OnInit {

  user!: any;
  roles!: any

  selectedRole: any;

  selectedPermissions: any = [];

  constructor(
    private store: Store<AppState>,
    private accountService: AccountService,
    private bsModalService: BsModalService,
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
  }

  proceed(){
    const payload = {
      email: this.user?.email,
      role: this.selectedRole?.name
    }

    console.log({payload})
    this.modalService.showLoadingSpinner();
    this.accountService.assignRoleToUser(payload).toPromise().then((res) => {
      if(res){
        console.log({res});
        this.modalService.hideLoadingSpinner();
        this.store.dispatch(getUsers());
        this.modalService.hideAssignRole();
        this.modalService.successModal(`Role has been assigned to ${this.user?.name}`);
      }
    }).catch((err) => {
      console.log({err});
      this.modalService.hideLoadingSpinner();
      this.modalService.errorModal(err?.error?.message);
    })
  }

  cancel(){
    this.bsModalService.hide();
  }

  onChange(value: any): void {
    this.selectedRole = value;
  }
}
