import { createReducer, on } from '@ngrx/store';
import * as fromImageActions from '../actions/image.actions';

export const featureKey = 'image';

export interface State {
    uploadUrl: string
}

export const initialState: State = {
    uploadUrl: '',
}

export const reducer = createReducer(
    initialState,
    on(fromImageActions.getUploadUrlSuccess, (state: State, props: any) => ({
        ...state,
        uploadUrl: props.data
    })),
);

