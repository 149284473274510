import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AccountService } from 'src/app/services/account.service';
import { ModalService } from 'src/app/services/modal.service';
import { getRoles } from 'src/app/store/actions/account.actions';
import { logIn } from 'src/app/store/actions/auth.actions';
import { setLoadingSpinner } from 'src/app/store/actions/shared.actions';
import { AppState } from 'src/app/store/app.state';
import { MustMatch } from 'src/app/validators/mustmatch.validator';

@Component({
  selector: 'app-create-role',
  templateUrl: './create-role.component.html',
  styleUrls: ['./create-role.component.scss']
})
export class CreateRoleComponent implements OnInit {

  form = new FormGroup({});

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private accountService: AccountService,
    private bsModalService: BsModalService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      role: ['', [Validators.required]],
    })
  }

  get role(){
    return this.form.get('role');
  }

  get password(){
    return this.form.get('password');
  }

  login(){
    const payload = {
      role: this.role?.value,
    }

    console.log({payload})

    this.modalService.showLoadingSpinner();
    this.accountService.createRole(payload).toPromise().then((res) => {
      if(res){
        console.log({res});
        this.modalService.hideLoadingSpinner();
        this.modalService.hideCreateRole();
        this.modalService.successModal(`Role ${this.role?.value} has been successfully created.`)
        this.store.dispatch(getRoles());
      }
    }).catch((err) => {
      this.modalService.hideLoadingSpinner();
      this.modalService.errorModal(err?.error?.message);
    })
  }

  cancel(){
    this.bsModalService.hide();
  }
}
