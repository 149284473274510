<!-- <div class="container-fluid main">
  <div class="row">
    <ng-container *ngIf="true; then withSideNav; else withoutSideNav"></ng-container>

    <ng-template #withSideNav>
      <app-home-side-nav></app-home-side-nav>
      <main class="col-lg-12 ms-sm-auto col-xl-12 p-0">
        <div class="container-fluid side-nav">
          <div class="row flex-nowrap">
            <div class="col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-dark">
              <div class="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
                <a href="/" class="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                    <span class="fs-5 d-none d-sm-inline">Menu</span>
                </a>
                <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
                  <li class="nav-item">
                    <a href="#" class="nav-link align-middle px-0">
                      <i class="fs-4 bi-house"></i> <span class="ms-1 d-none d-sm-inline">Home</span>
                    </a>
                  </li>
                  <li>
                    <a href="#submenu1" data-bs-toggle="collapse" class="nav-link px-0 align-middle">
                      <i class="fs-4 bi-speedometer2"></i> <span class="ms-1 d-none d-sm-inline">Dashboard</span>
                    </a>
                    <ul class="collapse show nav flex-column ms-1" id="submenu1" data-bs-parent="#menu">
                      <li class="w-100">
                        <a href="#" class="nav-link px-0"> <span class="d-none d-sm-inline">Item</span> 1 </a>
                      </li>
                      <li>
                        <a href="#" class="nav-link px-0"> <span class="d-none d-sm-inline">Item</span> 2 </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#" class="nav-link px-0 align-middle">
                      <i class="fs-4 bi-table"></i> <span class="ms-1 d-none d-sm-inline">Orders</span>
                    </a>
                  </li>
                  <li>
                    <a href="#submenu2" data-bs-toggle="collapse" class="nav-link px-0 align-middle ">
                      <i class="fs-4 bi-bootstrap"></i> <span class="ms-1 d-none d-sm-inline">Bootstrap</span>
                    </a>
                    <ul class="collapse nav flex-column ms-1" id="submenu2" data-bs-parent="#menu">
                      <li class="w-100">
                        <a href="#" class="nav-link px-0"> <span class="d-none d-sm-inline">Item</span> 1</a>
                      </li>
                      <li>
                        <a href="#" class="nav-link px-0"> <span class="d-none d-sm-inline">Item</span> 2</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#submenu3" data-bs-toggle="collapse" class="nav-link px-0 align-middle">
                      <i class="fs-4 bi-grid"></i> <span class="ms-1 d-none d-sm-inline">Products</span> 
                    </a>
                      <ul class="collapse nav flex-column ms-1" id="submenu3" data-bs-parent="#menu">
                        <li class="w-100">
                          <a href="#" class="nav-link px-0"> <span class="d-none d-sm-inline">Product</span> 1</a>
                        </li>
                        <li>
                          <a href="#" class="nav-link px-0"> <span class="d-none d-sm-inline">Product</span> 2</a>
                        </li>
                        <li>
                          <a href="#" class="nav-link px-0"> <span class="d-none d-sm-inline">Product</span> 3</a>
                        </li>
                        <li>
                          <a href="#" class="nav-link px-0"> <span class="d-none d-sm-inline">Product</span> 4</a>
                        </li>
                      </ul>
                  </li>
                  <li>
                    <a href="#" class="nav-link px-0 align-middle">
                      <i class="fs-4 bi-people"></i> <span class="ms-1 d-none d-sm-inline">Customers</span> 
                    </a>
                  </li>
                </ul>
                <div class="dropdown pb-4">
                    <a href="#" class="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                        <img src="https://github.com/mdo.png" alt="hugenerd" width="30" height="30" class="rounded-circle">
                        <span class="d-none d-sm-inline mx-1">loser</span>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-dark text-small shadow">
                        <li><a class="dropdown-item" href="#">New project...</a></li>
                        <li><a class="dropdown-item" href="#">Settings</a></li>
                        <li><a class="dropdown-item" href="#">Profile</a></li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>
                        <li><a class="dropdown-item" href="#">Sign out</a></li>
                    </ul>
                </div>
              </div>
            </div>
            <div class="col py-3">
              <app-page-content class="full-width"></app-page-content>
            </div>
          </div>
        </div>
      </main>
    </ng-template>

    <ng-template #withoutSideNav>
      <main class="col-lg-12 ms-sm-auto col-xl-12 px-md-4">
        <app-page-content class="full-width"></app-page-content>
      </main>
    </ng-template>
    
  </div>
</div> -->

<mat-drawer-container class="example-container" [hasBackdrop]="false">
  <mat-drawer #drawer [mode]="'push'">
    <div class="row head-row">
      <div class="col-md-12 d-flex justify-content-between align-items-center">
        <img src="../../../../assets/img/abyan.png" alt="" class="abyan-logo" *ngIf="drawer.opened">
        <button mat-icon-button (click)="drawer.toggle()" class="example-icon" aria-label="Example icon-button with menu icon">
          <mat-icon>menu</mat-icon>
        </button>
      </div>
    </div>

    <div class="panels" [ngClass]="{'align-center': !drawer.opened}" (mouseenter) ="drawer.open()" (mouseleave) ="drawer.close()">
      <div class="expansion-panels">
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title *ngIf="drawer.opened" class="jakarta"> Main Menu </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col-md-12 d-flex align-items-center">
              <a href="/home/dashboard" routerLink="/home/dashboard" routerLinkActive="active" class="nav-link d-flex justify-content-between" (click)="drawer.close()">
                <img src="assets/img/side-nav/dashboard.png" alt="" class="nav-img" *ngIf="router.url !== '/home/dashboard'">
                <img src="assets/img/side-nav/dashboard-active.png" alt="" class="nav-img" *ngIf="router.url == '/home/dashboard'">
                <p class="t-fs14 t-fw600 m-0 jakarta" *ngIf="drawer.opened" [ngClass]="{'nav-link-active': router.url == '/home/dashboard'}">Dashboard</p>
              </a>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-md-12 d-flex align-items-center">
              <a href="/home/deliveries" routerLink="/home/deliveries" routerLinkActive="active" class="nav-link d-flex justify-content-between" (click)="drawer.close()">
                <img src="assets/img/side-nav/deliveries.png" alt="" class="nav-img" *ngIf="router.url !== '/home/deliveries'">
                <img src="assets/img/side-nav/deliveries-active.png" alt="" class="nav-img" *ngIf="router.url == '/home/deliveries'">
                <p class="t-fs14 t-fw600 m-0 jakarta" *ngIf="drawer.opened" [ngClass]="{'nav-link-active': router.url == '/home/deliveries'}">Deliveries</p>
              </a>
            </div>
          </div>
  
          <div class="row mt-4" *ngIf="userProfile?.roles[0]?.name == 'dispatcher'">
            <div class="col-md-12 d-flex align-items-center">
              <a href="/home/to-pickup" routerLink="/home/to-pickup" routerLinkActive="active" class="nav-link d-flex justify-content-between" (click)="drawer.close()">
                <img src="assets/img/side-nav/deliveries.png" alt="" class="nav-img" *ngIf="router.url !== '/home/to-pickup'">
                <img src="assets/img/side-nav/deliveries-active.png" alt="" class="nav-img" *ngIf="router.url == '/home/to-pickup'">
                <p class="t-fs14 t-fw600 m-0 jakarta" *ngIf="drawer.opened" [ngClass]="{'nav-link-active': router.url == '/home/to-pickup'}">To Pickup</p>
              </a>
            </div>
          </div>
  
          <div class="row mt-4" *ngIf="userProfile?.roles[0]?.name == 'super-administrator' || userProfile?.roles[0]?.name == 'administrator'">
            <div class="col-md-12 d-flex align-items-center">
              <a href="/home/deliveries" routerLink="/home/unassigned-deliveries" routerLinkActive="active" class="nav-link d-flex justify-content-between" (click)="drawer.close()">
                <img src="assets/img/side-nav/assign.png" alt="" class="nav-img" *ngIf="router.url !== '/home/unassigned-deliveries'">
                <img src="assets/img/side-nav/assign-active.png" alt="" class="nav-img" *ngIf="router.url == '/home/unassigned-deliveries'">
                <p class="t-fs14 t-fw600 m-0 jakarta" *ngIf="drawer.opened" [ngClass]="{'nav-link-active': router.url == '/home/unassigned-deliveries'}">Unassigned Deliveries</p>
              </a>
            </div>
          </div>
  
          <div class="row mt-4" *ngIf="userProfile?.roles[0]?.name == 'super-administrator' || userProfile?.roles[0]?.name == 'administrator'">
            <div class="col-md-12 d-flex align-items-center">
              <a href="/home/assigned-deliveries" routerLink="/home/assigned-deliveries" routerLinkActive="active" class="nav-link d-flex justify-content-between" (click)="drawer.close()">
                <img src="assets/img/side-nav/assign.png" alt="" class="nav-img" *ngIf="router.url !== '/home/assigned-deliveries'">
                <img src="assets/img/side-nav/assign-active.png" alt="" class="nav-img" *ngIf="router.url == '/home/assigned-deliveries'">
                <p class="t-fs14 t-fw600 m-0 jakarta" *ngIf="drawer.opened" [ngClass]="{'nav-link-active': router.url == '/home/assigned-deliveries'}">Assigned Deliveries</p>
              </a>
            </div>
          </div>
          
          <div class="row mt-4" *ngIf="userProfile?.roles[0]?.name == 'super-administrator' || userProfile?.roles[0]?.name == 'administrator'">
            <div class="col-md-12 d-flex align-items-center">
              <a href="/home/riders" routerLink="/home/riders" routerLinkActive="active" class="nav-link d-flex justify-content-between" (click)="drawer.close()">
                <img src="assets/img/side-nav/riders.png" alt="" class="nav-img" *ngIf="router.url !== '/home/riders'">
                <img src="assets/img/side-nav/riders-active.png" alt="" class="nav-img" *ngIf="router.url == '/home/riders'">
                <p class="t-fs14 t-fw600 m-0 jakarta" *ngIf="drawer.opened" [ngClass]="{'nav-link-active': router.url == '/home/riders'}">Rider Management</p>
              </a>
            </div>
          </div>
  
          <div class="row mt-4" *ngIf="userProfile?.roles[0]?.name == 'super-administrator' || userProfile?.roles[0]?.name == 'administrator'">
            <div class="col-md-12 d-flex align-items-center">
              <a href="/home/users" routerLink="/home/users" routerLinkActive="active" class="nav-link d-flex justify-content-between" (click)="drawer.close()">
                <img src="assets/img/side-nav/users.png" alt="" class="nav-img" *ngIf="router.url !== '/home/users'">
                <img src="assets/img/side-nav/users-active.png" alt="" class="nav-img" *ngIf="router.url == '/home/users'">
                <p class="t-fs14 t-fw600 m-0 jakarta" *ngIf="drawer.opened" [ngClass]="{'nav-link-active': router.url == '/home/users'}">User Management</p>
              </a>
            </div>
          </div>
  
          <div class="row mt-4" *ngIf="userProfile?.roles[0]?.name == 'super-administrator' || userProfile?.roles[0]?.name == 'administrator'">
            <div class="col-md-12 d-flex align-items-center">
              <a href="/home/roles" routerLink="/home/roles" routerLinkActive="active" class="nav-link d-flex justify-content-between" (click)="drawer.close()">
                <img src="assets/img/side-nav/roles.png" alt="" class="nav-img" *ngIf="router.url !== '/home/roles'">
                <img src="assets/img/side-nav/roles-active.png" alt="" class="nav-img" *ngIf="router.url == '/home/roles'">
                <p class="t-fs14 t-fw600 m-0 jakarta" *ngIf="drawer.opened" [ngClass]="{'nav-link-active': router.url == '/home/roles'}">Roles And Permission</p>
              </a>
            </div>
          </div>
  
          <div class="row mt-4" *ngIf="userProfile?.roles[0]?.name == 'super-administrator' || userProfile?.roles[0]?.name == 'administrator'">
            <div class="col-md-12 d-flex align-items-center">
              <a href="/home/sms-status" routerLink="/home/sms-status" routerLinkActive="active" class="nav-link d-flex justify-content-between" (click)="drawer.close()">
                <img src="assets/img/side-nav/roles.png" alt="" class="nav-img" *ngIf="router.url !== '/home/sms-status'">
                <img src="assets/img/side-nav/roles-active.png" alt="" class="nav-img" *ngIf="router.url == '/home/sms-status'">
                <p class="t-fs14 t-fw600 m-0 jakarta" *ngIf="drawer.opened" [ngClass]="{'nav-link-active': router.url == '/home/sms-status'}">SMS Status</p>
              </a>
            </div>
          </div>

          <div class="row mt-4" *ngIf="userProfile?.roles[0]?.name == 'super-administrator' || userProfile?.roles[0]?.name == 'administrator'">
            <div class="col-md-12 d-flex align-items-center">
              <a href="/home/reconciliation" routerLink="/home/reconciliation" routerLinkActive="active" class="nav-link d-flex justify-content-between" (click)="drawer.close()">
                <img src="assets/img/side-nav/roles.png" alt="" class="nav-img" *ngIf="router.url !== '/home/reconciliation'">
                <img src="assets/img/side-nav/roles-active.png" alt="" class="nav-img" *ngIf="router.url == '/home/reconciliation'">
                <p class="t-fs14 t-fw600 m-0 jakarta" *ngIf="drawer.opened" [ngClass]="{'nav-link-active': router.url == '/home/reconciliation'}">Reconciliation</p>
              </a>
            </div>
          </div>

          <div class="row mt-4" *ngIf="userProfile?.roles[0]?.name == 'super-administrator' || userProfile?.roles[0]?.name == 'administrator'">
            <div class="col-md-12 d-flex align-items-center">
              <a href="/home/territories" routerLink="/home/territories" routerLinkActive="active" class="nav-link d-flex justify-content-between" (click)="drawer.close()">
                <img src="assets/img/side-nav/roles.png" alt="" class="nav-img" *ngIf="router.url !== '/home/territories'">
                <img src="assets/img/side-nav/roles-active.png" alt="" class="nav-img" *ngIf="router.url == '/home/territories'">
                <p class="t-fs14 t-fw600 m-0 jakarta" *ngIf="drawer.opened" [ngClass]="{'nav-link-active': router.url == '/home/territories'}">Territories</p>
              </a>
            </div>
          </div>
        </mat-expansion-panel>

        <!-- <mat-expansion-panel [expanded]="false">
          <mat-expansion-panel-header>
            <mat-panel-title *ngIf="drawer.opened" class="jakarta"> Delivery</mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col-md-12 d-flex align-items-center">
              <a href="/home/deliveries" routerLink="/home/deliveries" routerLinkActive="active" class="nav-link d-flex justify-content-between" (click)="drawer.close()">
                <img src="assets/img/side-nav/deliveries.png" alt="" class="nav-img" *ngIf="router.url !== '/home/deliveries'">
                <img src="assets/img/side-nav/deliveries-active.png" alt="" class="nav-img" *ngIf="router.url == '/home/deliveries'">
                <p class="t-fs14 t-fw600 m-0 jakarta" *ngIf="drawer.opened" [ngClass]="{'nav-link-active': router.url == '/home/deliveries'}">Deliveries</p>
              </a>
            </div>
          </div>
  
          <div class="row mt-4" *ngIf="userProfile?.roles[0]?.name == 'dispatcher'">
            <div class="col-md-12 d-flex align-items-center">
              <a href="/home/to-pickup" routerLink="/home/to-pickup" routerLinkActive="active" class="nav-link d-flex justify-content-between" (click)="drawer.close()">
                <img src="assets/img/side-nav/deliveries.png" alt="" class="nav-img" *ngIf="router.url !== '/home/to-pickup'">
                <img src="assets/img/side-nav/deliveries-active.png" alt="" class="nav-img" *ngIf="router.url == '/home/to-pickup'">
                <p class="t-fs14 t-fw600 m-0 jakarta" *ngIf="drawer.opened" [ngClass]="{'nav-link-active': router.url == '/home/to-pickup'}">To Pickup</p>
              </a>
            </div>
          </div>
  
          <div class="row mt-4" *ngIf="userProfile?.roles[0]?.name == 'super-administrator' || userProfile?.roles[0]?.name == 'administrator'">
            <div class="col-md-12 d-flex align-items-center">
              <a href="/home/deliveries" routerLink="/home/unassigned-deliveries" routerLinkActive="active" class="nav-link d-flex justify-content-between" (click)="drawer.close()">
                <img src="assets/img/side-nav/assign.png" alt="" class="nav-img" *ngIf="router.url !== '/home/unassigned-deliveries'">
                <img src="assets/img/side-nav/assign-active.png" alt="" class="nav-img" *ngIf="router.url == '/home/unassigned-deliveries'">
                <p class="t-fs14 t-fw600 m-0 jakarta" *ngIf="drawer.opened" [ngClass]="{'nav-link-active': router.url == '/home/unassigned-deliveries'}">Unassigned Deliveries</p>
              </a>
            </div>
          </div>
  
          <div class="row mt-4" *ngIf="userProfile?.roles[0]?.name == 'super-administrator' || userProfile?.roles[0]?.name == 'administrator'">
            <div class="col-md-12 d-flex align-items-center">
              <a href="/home/assigned-deliveries" routerLink="/home/assigned-deliveries" routerLinkActive="active" class="nav-link d-flex justify-content-between" (click)="drawer.close()">
                <img src="assets/img/side-nav/assign.png" alt="" class="nav-img" *ngIf="router.url !== '/home/assigned-deliveries'">
                <img src="assets/img/side-nav/assign-active.png" alt="" class="nav-img" *ngIf="router.url == '/home/assigned-deliveries'">
                <p class="t-fs14 t-fw600 m-0 jakarta" *ngIf="drawer.opened" [ngClass]="{'nav-link-active': router.url == '/home/assigned-deliveries'}">Assigned Deliveries</p>
              </a>
            </div>
          </div>
        </mat-expansion-panel> -->
      </div>

      <div class="bottom-container row">
        <div class="col-md-12 d-flex align-items-center justify-content-center" [ngClass]="{'profile-container': drawer.opened}" *ngIf="userProfile">
          <div class="image-container" [ngClass]="{'mr': drawer.opened}">

          </div>
          <div class="text-container" *ngIf="drawer.opened">
            <div class="t-fs14 jakarta">{{ userProfile?.name }}</div>
            <div class="t-fs12 jakarta capitalize">{{ getRole(userProfile?.roles[0]?.name) }}</div>
          </div>
        </div>
        <div class="col-md-12 mt-3 d-flex align-items-center pointer" [ngClass]="{'justify-content-center': !drawer.opened}" (click)="logout()">
          <img src="assets/img/logout.svg" alt="" [ngClass]="{'mr': drawer.opened}">
          <div class="t-fs14 jakarta" *ngIf="drawer.opened">Logout</div>
        </div>
      </div>
    </div>
  </mat-drawer>
  <mat-drawer-content [ngClass]="{'padding-left': !drawer.opened }">
    <div class="row">
      <div class="col-md-12">
        <app-page-content></app-page-content>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>