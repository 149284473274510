import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { ModalService } from 'src/app/services/modal.service';
import { autoLogout } from 'src/app/store/actions/auth.actions';
import { AppState } from 'src/app/store/app.state';
import { getUser } from 'src/app/store/selectors/account.selectors';

@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html',
  styleUrls: ['./home-layout.component.scss']
})
export class HomeLayoutComponent implements OnInit {

  getUserProfile$: Observable<any>;
  userProfile: any;

  ngUnsubscrie = new Subject();

  panelOpenState = false;

  constructor(
    private store: Store<AppState>,
    public router: Router,
    private modalService: ModalService
  ) { 
    this.getUserProfile$ = this.store.select(getUser);
  }

  ngOnInit(): void {
    this.getUserProfile$.pipe(takeUntil(this.ngUnsubscrie)).subscribe((res) => {
      if(res){
        console.log({userProfile: res})
        this.userProfile = res;
      }
    });

    console.log(this.router.url);
  }

  getRole(str: any){
    var withNoDigits = str.replace('-', ' ');
    return withNoDigits;
  }

  logout(){
    this.store.dispatch(autoLogout());
  }

  canCreateUser(){
    var createUserPermission = this.userProfile?.roles[0]?.permissions?.filter((e: any) => e?.name == 'create users')[0];
    if(createUserPermission){
      return true;
    }
    else{
      return false;
    }
  }

  canUpdateDeliveries(){
    var createUserPermission = this.userProfile?.roles[0]?.permissions?.filter((e: any) => e?.name == 'update deliveries')[0];
    if(createUserPermission){
      return true;
    }
    else{
      return false;
    }
  }

  canUpdateRiders(){
    var createUserPermission = this.userProfile?.roles[0]?.permissions?.filter((e: any) => e?.name == 'update riders')[0];
    if(createUserPermission){
      return true;
    }
    else{
      return false;
    }
  }

  canAssignRiders(){
    var createUserPermission = this.userProfile?.roles[0]?.permissions?.filter((e: any) => e?.name == 'assigns riders')[0];
    if(createUserPermission){
      return true;
    }
    else{
      return false;
    }
  }

  canCreateItem(){
    var createUserPermission = this.userProfile?.roles[0]?.permissions?.filter((e: any) => e?.name == 'create item')[0];
    if(createUserPermission){
      return true;
    }
    else{
      return false;
    }
  }

  canUpdateItem(){
    var createUserPermission = this.userProfile?.roles[0]?.permissions?.filter((e: any) => e?.name == 'update item')[0];
    if(createUserPermission){
      return true;
    }
    else{
      return false;
    }
  }
}
