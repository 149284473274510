import { Injectable, NgZone } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, exhaustMap, catchError, tap, withLatestFrom, mergeMap, filter, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as fromImageActions from '../actions/image.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { ErrorService } from '../../services/error.service';
import { setLoadingSpinner } from '../actions/shared.actions';
import { AlertService } from '../../services/alert.service';
import { AccountService } from 'src/app/services/account.service';
import { SharedService } from 'src/app/services/shared.service';

@Injectable()
export class ImageEffects { 

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private errorService: ErrorService,
    private alertService: AlertService,
    private accountService: AccountService,
    private sharedService: SharedService,
    private ngZone: NgZone
  ) {}
}