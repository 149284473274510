<div class="row">
	<div class="col-md-12">
		<h1 class="t-fs22 t-fw700 m-0">Take a photo</h1>
		<h3 class="t-fs16 t-fw400 m-0">Take a clear photo of item</h3>
	</div>
</div>

<div class="row">
	<div class="col-md-12">
		<webcam [height]="500" [width]="500" [trigger]="triggerObservable" (imageCapture)="handleImage($event)" *ngIf="showWebcam"
		[allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
		[videoOptions]="videoOptions"
		[imageQuality]="1"
		(cameraSwitched)="cameraWasSwitched($event)"
		(initError)="handleInitError($event)">
	</webcam>
	</div>
</div>

<div class="row">
	<div class="col-md-12 d-flex justify-content-center">
		<div class="snapshot" *ngIf="webcamImage">
			<img [src]="webcamImage.imageAsDataUrl" alt="webcam image" class="captured">
		</div>
	</div>
</div>

<div class="row mt-2">
	<div class="col-md-12">
		<button class="btn btn-info btn-block actionBtn" (click)="triggerSnapshot();">Take A Snapshot</button>
	</div>
	<div class="col-md-12">
		<button class="btn btn-light actionBtn" (click)="dismiss();">Cancel</button>
	</div>
</div>

<div class="row mt-2" *ngIf="webcamImage">
	<div class="col-md-12">
		<button class="btn btn-info btn-block actionBtn" (click)="close()">Confirm</button>
	</div>
</div>
<!-- <div style="text-align:center">
	<div class="row">
		<div class="col-md-12">
			<webcam [height]="500" [width]="500" [trigger]="triggerObservable" (imageCapture)="handleImage($event)" *ngIf="showWebcam"
			[allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
			[videoOptions]="videoOptions"
			[imageQuality]="1"
			(cameraSwitched)="cameraWasSwitched($event)"
			(initError)="handleInitError($event)">
		</webcam>
		</div>
	</div>

	<div class="row p-3">
		<div class="col-md-6">
			<button class="btn btn-primary btn-block actionBtn" (click)="triggerSnapshot();">Take A Snapshot</button>
		</div>
		<div class="col-md-6">
			<button class="btn btn-primary btn-block actionBtn" (click)="toggleWebcam();">Toggle Webcam</button>
		</div>
	</div>

	<div class="row">
		<div class="col-md-12">
			<div class="snapshot" *ngIf="webcamImage">
				<h2>Nice one!</h2>
				<img [src]="webcamImage.imageAsDataUrl" alt="webcam image">
			</div>
		</div>
	</div>

	<div class="row p-3" *ngIf="webcamImage">
		<div class="col-md-12">
			<button class="btn btn-primary btn-block actionBtn" (click)="close()">Confirm</button>
		</div>
	</div> -->