<div class="grid">
	<div class="row p-3">
		<div class="col-md-12">
			<div class="list-group">
				<label class="list-group-item capitalize d-flex justify-content-between align-center" *ngFor="let item of riders">
					<div class="input-container">
						<input class="form-check-input me-1" type="radio" [value]="item" [(ngModel)]="rider">
						{{ item?.nameFirst }} {{ item?.nameLast }}
					</div>
					<div class="status-container t-fs14 t-lh20 t-fw700 text-center">
						{{item?.status}}
					</div>
				</label>
			</div>
		</div>
	</div>

	<div class="row row-buttons">
		<div class="col-md-12 d-flex justify-content-center">
			<button class="btn btn-primary" mat-raised-button color="primary" (click)="proceed()">Proceed</button>
			<button mat-raised-button color="danger" class="cancel" (click)="cancel()">Cancel</button>
		</div>
	</div>
</div>