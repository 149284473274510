import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { autoLogout } from 'src/app/store/actions/auth.actions';
import { AppState } from 'src/app/store/app.state';
import { getUser } from 'src/app/store/selectors/account.selectors';

@Component({
  selector: 'app-home-side-nav',
  templateUrl: './home-side-nav.component.html',
  styleUrls: ['./home-side-nav.component.scss']
})
export class HomeSideNavComponent implements OnInit {

  getUserProfile$: Observable<any>;
  userProfile: any;

  ngUnsubscrie = new Subject();

  constructor(
    private store: Store<AppState>
  ) {
    this.getUserProfile$ = this.store.select(getUser);
  }

  ngOnInit(): void {
    this.getUserProfile$.pipe(takeUntil(this.ngUnsubscrie)).subscribe((res) => {
      if(res){
        console.log({userProfile: res})
        this.userProfile = res;
      }
    })
  }

  logout(){
    this.store.dispatch(autoLogout());
  }

}
