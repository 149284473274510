/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';

export enum ActionTypes {
  GET_USER_PROFILE = '[Account] Update User Profile',
  GET_USER_PROFILE_SUCCESS = '[Account] User Profile Success',
  UPDATE_USER_PROFILE = '[Account] Update User Profile',
  UPDATE_USER_PROFILE_SUCCESS = '[Account] Update User Profile Success',

  GET_DELIVERIES = '[Account] Get Deliveries',
  GET_DELIVERIES_SUCCESS = '[Account] Get Deliveries Success',

  GET_RIDERS = '[Account] Get Riders',
  GET_RIDERS_SUCCESS = '[Account] Get Riders Success',

  UPDATE_RIDER_STATUS = '[Account] Update Rider Status',
  UPDATE_RIDER_STATUS_SUCCESS = '[Account] Update Rider Status Success',

  ASSIGN_DELIVERY = '[Account] Assign Delivery',
  ASSIGN_DELIVERY_SUCCESS = '[Account] Assign Delivery Success',

  GET_ROLES = '[Account] Get Roles',
  GET_ROLES_SUCCESS = '[Account] Get Roles Success',

  GET_PERMISSIONS = '[Account] Get Permissions',
  GET_PERMISSIONS_SUCCESS = '[Account] Get Permissions Success',

  GET_USERS_SUCCESS = '[Account] Get Users',
  GET_USERS_SUCCESS_SUCCESS = '[Account] Get Users Success',

  CAPTURE_IMAGE = '[Account] Capture Image',
  CAPTURE_IMAGE_SUCCESS = '[Account] Capture Image Success',

  SEQUENCE_DELIVERIES = '[Account] Sequence Deliveries',
  SEQUENCE_DELIVERIES_SUCCESS = '[Account] Sequence Deliveries Success',

  GET_UNASSIGNED_DELIVERIES = '[Account] Get Unassigned Deliveries',
  GET_UNASSIGNED_DELIVERIES_SUCCESS = '[Account] Get Unassigned Deliveries Success',

  GET_ASSIGNED_DELIVERIES = '[Account] Get Assigned Deliveries',
  GET_ASSIGNED_DELIVERIES_SUCCESS = '[Account] Get Assigned Deliveries Success',

  GET_DELIVERY_STATUSES = '[Account] Get Delivery Statuses',
  GET_DELIVERY_STATUSES_SUCCESS = '[Account] Get Delivery Statuses Success',

  SELECT_STATUS_FILTER = '[Account] Select Status Filter',

  GET_DELIVERIES_COUNT = '[Account] Get Deliveries Count',
  GET_DELIVERIES_COUNT_SUCCESS = '[Account] Get Deliveries Count Success',

  GET_RIDERS_COUNT = '[Account] Get Riders Count',
  GET_RIDERS_COUNT_SUCCESS = '[Account] Get Riders Count Success',

  GET_REVENUE_COUNT = '[Account] Get Revenue Count',
  GET_REVENUE_COUNT_SUCCESS = '[Account] Get Revenue Count Success',

  GET_PAYMENT_COUNT = '[Account] Get Payment Count',
  GET_PAYMENT_COUNT_SUCCESS = '[Account] Get Payment Count Success',

  GET_SMS_STATUS = '[Account] Get SMS Status',
  GET_SMS_STATUS_SUCCESS = '[Account] Get SMS Status Success',
  CREATE_SMS_STATUS = '[Account] Create SMS Status',
  CREATE_SMS_STATUS_SUCCESS = '[Account] Create SMS Status Success',
  UPDATE_SMS_STATUS = '[Account] Update SMS Status',
  UPDATE_SMS_STATUS_SUCCESS = '[Account] Update SMS Status Success',
  DELETE_SMS_STATUS = '[Account] Delete SMS Status',
  DELETE_SMS_STATUS_SUCCESS = '[Account] Delete SMS Status Success',

  MANUAL_RECONCILIATION = '[Account] Create SMS Status',
  MANUAL_RECONCILIATION_SUCCESS = '[Account] Create SMS Status Success',

  GET_TERRITORIES = '[Account] Get Territories',
  GET_TERRITORIES_SUCCESS = '[Account] Get Territories Success',
  CREATE_TERRITORIES = '[Account] Create Territories',
  CREATE_TERRITORIES_SUCCESS = '[Account] Create Territories Success',
  UPDATE_TERRITORIES = '[Account] Update Territories',
  UPDATE_TERRITORIES_SUCCESS = '[Account] Update Territories Success',

  GET_RIDER_TERRITORIES = '[Account] Get Rider Territories',
  GET_RIDER_TERRITORIES_SUCCESS = '[Account] Get Rider Territories Success',
  CREATE_RIDER_TERRITORIES = '[Account] Create Rider Territories',
  CREATE_RIDER_TERRITORIES_SUCCESS = '[Account] Create Rider Territories Success',
  UPDATE_RIDER_TERRITORIES = '[Account] Update Rider Territories',
  UPDATE_RIDER_TERRITORIES_SUCCESS = '[Account] Update Rider Territories Success',

  GET_USER_TERRITORIES = '[Account] Get User Territories',
  GET_USER_TERRITORIES_SUCCESS = '[Account] Get User Territories Success',
  CREATE_USER_TERRITORIES = '[Account] Create User Territories',
  CREATE_USER_TERRITORIES_SUCCESS = '[Account] Create User Territories Success',
  UPDATE_USER_TERRITORIES = '[Account] Update User Territories',
  UPDATE_USER_TERRITORIES_SUCCESS = '[Account] Update User Territories Success',

  GET_TERRITORY_AREA = '[Account] Get Territory Area',
  GET_TERRITORY_AREA_SUCCESS = '[Account] Get Territory Area Success',
  CREATE_TERRITORY_AREA= '[Account] Create Territory Area',
  CREATE_TERRITORY_AREA_SUCCESS = '[Account] Create Territory Area Success',
  UPDATE_TERRITORY_AREA = '[Account] Update Territory Area',
  UPDATE_TERRITORY_AREA_SUCCESS = '[Account] Update Territory Area Success',

  GET_RECONCILIATION_REPORT = '[Account] Get Reconciliation Report',
  GET_RECONCILIATION_REPORT_SUCCESS = '[Account] Get Reconciliation Report Success',
}

export const getUserProfile = createAction(
  ActionTypes.GET_USER_PROFILE
);

export const getUserProfileSuccess = createAction(
  ActionTypes.GET_USER_PROFILE_SUCCESS,
  props<{ data: any }>()
);

export const updateUserProfile = createAction(
  ActionTypes.UPDATE_USER_PROFILE,
  props<{ data: any }>()
);

export const updateUserProfileSuccess = createAction(
  ActionTypes.UPDATE_USER_PROFILE_SUCCESS,
  props<{ data: any }>()
);

export const getDeliveries = createAction(
  ActionTypes.GET_DELIVERIES
);

export const getDeliveriesSuccess = createAction(
  ActionTypes.GET_DELIVERIES_SUCCESS,
  props<{ data: any }>()
);

export const getRiders = createAction(
  ActionTypes.GET_RIDERS
);

export const getRidersSuccess = createAction(
  ActionTypes.GET_RIDERS_SUCCESS,
  props<{ data: any }>()
);

export const updateRiderStatus = createAction(
  ActionTypes.UPDATE_RIDER_STATUS,
  props<{ data: any }>()
);

export const updateRiderStatusSuccess = createAction(
  ActionTypes.UPDATE_RIDER_STATUS_SUCCESS,
  props<{ data: any }>()
);

export const assignDelivery = createAction(
  ActionTypes.ASSIGN_DELIVERY,
  props<{ data: any }>()
);

export const assignDeliverySuccess = createAction(
  ActionTypes.ASSIGN_DELIVERY_SUCCESS,
  props<{ data: any }>()
);

export const getRoles = createAction(
  ActionTypes.GET_ROLES
);

export const getRolesSuccess = createAction(
  ActionTypes.GET_ROLES_SUCCESS,
  props<{ data: any }>()
);

export const getPermissions = createAction(
  ActionTypes.GET_PERMISSIONS
);

export const getPermissionsSuccess = createAction(
  ActionTypes.GET_PERMISSIONS_SUCCESS,
  props<{ data: any }>()
);

export const getUsers = createAction(
  ActionTypes.GET_USERS_SUCCESS
);

export const getUsersSuccess = createAction(
  ActionTypes.GET_USERS_SUCCESS_SUCCESS,
  props<{ data: any }>()
);

export const captureImage = createAction(
  ActionTypes.CAPTURE_IMAGE,
  props<{ data: any }>()
);

export const captureImageSuccess = createAction(
  ActionTypes.CAPTURE_IMAGE_SUCCESS,
  props<{ data: any }>()
);

export const sequenceDeliveries = createAction(
  ActionTypes.SEQUENCE_DELIVERIES,
  props<{ data: any }>()
);

export const sequenceDeliveriesSuccess = createAction(
  ActionTypes.SEQUENCE_DELIVERIES_SUCCESS,
  props<{ data: any }>()
);

export const getUnassignedDeliveries = createAction(
  ActionTypes.GET_UNASSIGNED_DELIVERIES
);

export const getUnassignedDeliveriesSuccess = createAction(
  ActionTypes.GET_UNASSIGNED_DELIVERIES_SUCCESS,
  props<{ data: any }>()
);

export const getAssignedDeliveries = createAction(
  ActionTypes.GET_ASSIGNED_DELIVERIES
);

export const getAssignedDeliveriesSuccess = createAction(
  ActionTypes.GET_ASSIGNED_DELIVERIES_SUCCESS,
  props<{ data: any }>()
);

export const getDeliveryStatuses = createAction(
  ActionTypes.GET_DELIVERY_STATUSES
);

export const getDeliveryStatusesSuccess = createAction(
  ActionTypes.GET_DELIVERY_STATUSES_SUCCESS,
  props<{ data: any }>()
);

export const selectStatusFilter = createAction(
  ActionTypes.SELECT_STATUS_FILTER,
  props<{ data: any }>()
);

export const getDeliveriesCount = createAction(
  ActionTypes.GET_DELIVERIES_COUNT
);

export const getDeliveriesCountSuccess = createAction(
  ActionTypes.GET_DELIVERIES_COUNT_SUCCESS,
  props<{ data: any }>()
);

export const getRidersCount = createAction(
  ActionTypes.GET_RIDERS_COUNT
);

export const getRidersCountSuccess = createAction(
  ActionTypes.GET_RIDERS_COUNT_SUCCESS,
  props<{ data: any }>()
);

export const getRevenueCount = createAction(
  ActionTypes.GET_REVENUE_COUNT
);

export const getRevenueCountSuccess = createAction(
  ActionTypes.GET_REVENUE_COUNT_SUCCESS,
  props<{ data: any }>()
);

export const getPaymentCount = createAction(
  ActionTypes.GET_PAYMENT_COUNT
);

export const getPaymentCountSuccess = createAction(
  ActionTypes.GET_PAYMENT_COUNT_SUCCESS,
  props<{ data: any }>()
);

export const getSmsStatus = createAction(
  ActionTypes.GET_SMS_STATUS
);

export const getSmsStatusSuccess = createAction(
  ActionTypes.GET_SMS_STATUS_SUCCESS,
  props<{ data: any }>()
);

export const createSmsStatus = createAction(
  ActionTypes.CREATE_SMS_STATUS,
  props<{ data: any }>()
);

export const createSmsStatusSuccess = createAction(
  ActionTypes.CREATE_SMS_STATUS_SUCCESS,
  props<{ data: any }>()
);

export const updateSmsStatus = createAction(
  ActionTypes.UPDATE_SMS_STATUS,
  props<{ data: any }>()
);

export const updateSmsStatusSuccess = createAction(
  ActionTypes.UPDATE_SMS_STATUS_SUCCESS,
  props<{ data: any }>()
);

export const deleteSmsStatus = createAction(
  ActionTypes.DELETE_SMS_STATUS,
  props<{ data: any }>()
);

export const deleteSmsStatusSuccess = createAction(
  ActionTypes.DELETE_SMS_STATUS_SUCCESS,
  props<{ data: any }>()
);

export const manualReconciliation = createAction(
  ActionTypes.MANUAL_RECONCILIATION
);

export const manualReconciliationSuccess = createAction(
  ActionTypes.MANUAL_RECONCILIATION_SUCCESS,
  props<{ data: any }>()
);

export const getTerritories = createAction(
  ActionTypes.GET_TERRITORIES
);

export const getTerritoriesSuccess = createAction(
  ActionTypes.GET_TERRITORIES_SUCCESS,
  props<{ data: any }>()
);

export const createTerritories = createAction(
  ActionTypes.CREATE_TERRITORIES,
  props<{ data: any }>()
);

export const createTerritoriesSuccess = createAction(
  ActionTypes.CREATE_TERRITORIES_SUCCESS,
  props<{ data: any }>()
);

export const updateTerritories = createAction(
  ActionTypes.UPDATE_TERRITORIES,
  props<{ data: any, territoryId: any }>()
);

export const updateTerritoriesSuccess = createAction(
  ActionTypes.UPDATE_TERRITORIES_SUCCESS,
  props<{ data: any }>()
);


export const getRiderTerritories = createAction(
  ActionTypes.GET_RIDER_TERRITORIES,
  props<{ territory: any }>()
);

export const getRiderTerritoriesSuccess = createAction(
  ActionTypes.GET_RIDER_TERRITORIES_SUCCESS,
  props<{ data: any, territory: any }>()
);

export const createRiderTerritories = createAction(
  ActionTypes.CREATE_RIDER_TERRITORIES,
  props<{ data: any, territory: any }>()
);

export const createRiderTerritoriesSuccess = createAction(
  ActionTypes.CREATE_RIDER_TERRITORIES_SUCCESS,
  props<{ data: any }>()
);

export const updateRiderTerritories = createAction(
  ActionTypes.UPDATE_RIDER_TERRITORIES,
  props<{ data: any, territory: any, id: any }>()
);

export const updateRiderTerritoriesSuccess = createAction(
  ActionTypes.UPDATE_RIDER_TERRITORIES_SUCCESS,
  props<{ data: any }>()
);


export const getUserTerritories = createAction(
  ActionTypes.GET_USER_TERRITORIES,
  props<{ territory: any }>()
);

export const getUserTerritoriesSuccess = createAction(
  ActionTypes.GET_USER_TERRITORIES_SUCCESS,
  props<{ data: any, territory: any }>()
);

export const createUserTerritories = createAction(
  ActionTypes.CREATE_USER_TERRITORIES,
  props<{ data: any, territory: any }>()
);

export const createUserTerritoriesSuccess = createAction(
  ActionTypes.CREATE_USER_TERRITORIES_SUCCESS,
  props<{ data: any }>()
);

export const updateUserTerritories = createAction(
  ActionTypes.UPDATE_USER_TERRITORIES,
  props<{ data: any, territory: any, id: any }>()
);

export const updateUserTerritoriesSuccess = createAction(
  ActionTypes.UPDATE_USER_TERRITORIES_SUCCESS,
  props<{ data: any }>()
);


export const getTerritoryArea = createAction(
  ActionTypes.GET_TERRITORY_AREA,
  props<{ territory: any }>()
);

export const getTerritoryAreaSucces = createAction(
  ActionTypes.GET_TERRITORY_AREA_SUCCESS,
  props<{ data: any, territory: any }>()
);

export const createTerritoryArea = createAction(
  ActionTypes.CREATE_TERRITORY_AREA,
  props<{ data: any, territory: any }>()
);

export const createTerritoryAreaSuccess = createAction(
  ActionTypes.CREATE_TERRITORY_AREA_SUCCESS,
  props<{ data: any }>()
);

export const updateTerritoryArea = createAction(
  ActionTypes.UPDATE_TERRITORY_AREA,
  props<{ data: any, territory: any, id: any }>()
);

export const updateTerritoryAreaSuccess = createAction(
  ActionTypes.UPDATE_TERRITORY_AREA_SUCCESS,
  props<{ data: any }>()
);

export const getReconcilationReport = createAction(
  ActionTypes.GET_RECONCILIATION_REPORT
);

export const getReconciliationReportSuccess = createAction(
  ActionTypes.GET_RECONCILIATION_REPORT_SUCCESS,
  props<{ data: any }>()
);