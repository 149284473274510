import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-roadmap-type',
  templateUrl: './roadmap-type.component.html',
  styleUrls: ['./roadmap-type.component.scss']
})
export class RoadmapTypeComponent implements OnInit {

  constructor(
    private bsModalService: BsModalService
  ) { }

  ngOnInit(): void {
  }

  hide(){
    this.bsModalService.hide()
  }
}
