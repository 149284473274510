import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AccountService } from 'src/app/services/account.service';
import { ModalService } from 'src/app/services/modal.service';
import { createRiderTerritories, createTerritories, getRoles, getSmsStatus, getUsers, updateRiderTerritories, updateUserTerritories } from 'src/app/store/actions/account.actions';
import { logIn } from 'src/app/store/actions/auth.actions';
import { setLoadingSpinner } from 'src/app/store/actions/shared.actions';
import { AppState } from 'src/app/store/app.state';
import { MustMatch } from 'src/app/validators/mustmatch.validator';

@Component({
  selector: 'app-update-user-territory',
  templateUrl: './update-user-territory.component.html',
  styleUrls: ['./update-user-territory.component.scss']
})
export class UpdateUserTerritoryComponent implements OnInit {

  users!: any;
  territory!: any;

  userTerritory!: any;

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private accountService: AccountService,
    private bsModalService: BsModalService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    console.log(this.userTerritory?.user?.id)
    this.form = this.fb.group({
      userId: ['', [Validators.required]],
      areaStatus: ['', [Validators.required]]
    });

    this.form.patchValue({
      userId: this.userTerritory?.user?.id,
      areaStatus: this.userTerritory?.areaStatus
    })
  }

  updateUserTerritory(){
    const payload = {
      userId: this.userId?.value,
      areaStatus: this.areaStatus?.value
    }

    console.log({payload})

    this.modalService.showLoadingSpinner();
    this.store.dispatch(updateUserTerritories({data: payload, territory: this.territory, id: this.userTerritory?.id}));
  }

  cancel(){
    this.modalService.hideUpdateUserTerritory();
  }

  get userId(){
    return this.form.get('userId');
  }

  get areaStatus(){
    return this.form.get('areaStatus');
  }
}
