import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import * as QRCode from 'qrcode';

@Directive({
  selector: '[appQrCode]'
})
export class QrCodeDirective implements OnInit {

  @Input('qrdata') value: string = '';
  options= {
    errorCorrectionLevel: "M",
    margin: 0,
    scale: 20,
    color: {
      dark: '#000000'
    }
  };

  constructor(
    public element: ElementRef
  ) { }

  ngOnInit() {
    this.generate(this.value);
  }

  ngOnChanges(changes: any) {
    if (changes.value) {
      this.generate(changes.value.currentValue);
    }
  }

  generate(data: any) {
    let canvas = document.createElement('canvas');
    QRCode.toDataURL(canvas, data, {}, (err: any, url: any) => {
      this.element.nativeElement.src = canvas.toDataURL('image/png');
      let context = canvas.getContext('2d');
      let size = canvas.width / 2.5;
      let pos = (canvas.width - size) / 2;
      let img = new Image();
      img.onload = () => {
        context?.drawImage(img, pos, pos, size, size);
        this.element.nativeElement.src = canvas.toDataURL('image/png');
      }
    });
  }

}
