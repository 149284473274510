import { createReducer, on } from '@ngrx/store';
import * as fromAuthActions from '../actions/auth.actions';
import * as fromAccountActions from '../actions/account.actions';
import * as fromRideAction from '../actions/ride.action';
import { UserProfile } from 'src/app/models/user-profile.model';

export const featureKey = 'account';

export interface State {
  user: any;
  riders: {
    data: Array<any>,
    isLoaded: Boolean
  },
  unAssignedDeliveries: {
    data: Array<any>,
    isLoaded: Boolean
  },
  assignedDeliveries: {
    data: Array<any>,
    isLoaded: Boolean
  },
  deliveryStatuses: {
    data: Array<any>,
    isLoaded: Boolean
  },
  deliveries: {
    data: Array<any>,
    isLoaded: Boolean
  },
  roles: {
    data: Array<any>,
    isLoaded: Boolean
  },
  permissions: {
    data: Array<any>,
    isLoaded: Boolean
  },
  userList: {
    data: Array<any>,
    isLoaded: Boolean
  },
  deliveryCount: {
    data: Array<any>,
    isLoaded: Boolean
  },
  ridersCount: {
    data: Array<any>,
    isLoaded: Boolean
  },
  revenueCount: {
    data: Array<any>,
    isLoaded: Boolean
  },
  paymentsCount: {
    data: Array<any>,
    isLoaded: Boolean
  },
  capturedImage: any;
  selectedFilter: any;

  smsStatus: {
    data: Array<any>,
    isLoaded: Boolean
  },

  territories: {
    data: Array<any>,
    isLoaded: Boolean
  },

  riderTerritories: {
    data: Array<any>,
    territory: any,
    isLoaded: Boolean
  },

  userTerritories: {
    data: Array<any>,
    territory: any,
    isLoaded: Boolean
  },

  territoryArea: {
    data: Array<any>,
    territory: any,
    isLoaded: Boolean
  },
}

export const initialState: State = {
  user: null,
  riders: {
    data: [],
    isLoaded: false
  },
  unAssignedDeliveries: {
    data: [],
    isLoaded: false
  },
  assignedDeliveries: {
    data: [],
    isLoaded: false
  },
  deliveryStatuses: {
    data: [],
    isLoaded: false
  },
  deliveries: {
    data: [],
    isLoaded: false
  },
  roles: {
    data: [],
    isLoaded: false
  },
  permissions: {
    data: [],
    isLoaded: false
  },
  userList: {
    data: [],
    isLoaded: false
  },
  deliveryCount: {
    data: [],
    isLoaded: false
  },
  ridersCount: {
    data: [],
    isLoaded: false
  },
  revenueCount: {
    data: [],
    isLoaded: false
  },
  paymentsCount: {
    data: [],
    isLoaded: false
  },
  capturedImage: null,
  selectedFilter: null,

  smsStatus: {
    data: [],
    isLoaded: false
  },

  territories: {
    data: [],
    isLoaded: false
  },

  riderTerritories: {
    data: [],
    territory: null,
    isLoaded: false
  },

  userTerritories: {
    data: [],
    territory: null,
    isLoaded: false
  },

  territoryArea: {
    data: [],
    territory: null,
    isLoaded: false
  },
};

export const reducer = createReducer(
  initialState,  
  on(fromAccountActions.updateUserProfileSuccess, (state: State, props: any) => ({
    ...state,
    user: props.data
  })),
  on(fromAccountActions.getRidersSuccess, (state: State, props: any) => ({
    ...state,
    riders: {data: props.data, isLoaded: true}
  })),
  on(fromAccountActions.getUnassignedDeliveriesSuccess, (state: State, props: any) => ({
    ...state,
    deliveries: {data: props.data, isLoaded: true}
  })),
  on(fromAccountActions.getUnassignedDeliveriesSuccess, (state: State, props: any) => ({
    ...state,
    unAssignedDeliveries: {data: props.data, isLoaded: true}
  })),
  on(fromAccountActions.getAssignedDeliveriesSuccess, (state: State, props: any) => ({
    ...state,
    assignedDeliveries: {data: props.data, isLoaded: true}
  })),
  on(fromAccountActions.getDeliveryStatusesSuccess, (state: State, props: any) => ({
    ...state,
    deliveryStatuses: {data: props.data, isLoaded: true}
  })),
  on(fromAccountActions.getRolesSuccess, (state: State, props: any) => ({
    ...state,
    roles: {data: props.data, isLoaded: true}
  })),
  on(fromAccountActions.getPermissionsSuccess, (state: State, props: any) => ({
    ...state,
    permissions: {data: props.data, isLoaded: true}
  })),
  on(fromAccountActions.getUsersSuccess, (state: State, props: any) => ({
    ...state,
    userList: {data: props.data, isLoaded: true}
  })),


  on(fromAccountActions.getDeliveriesCountSuccess, (state: State, props: any) => ({
    ...state,
    deliveryCount: {data: props.data, isLoaded: true}
  })),
  on(fromAccountActions.getRidersCountSuccess, (state: State, props: any) => ({
    ...state,
    ridersCount: {data: props.data, isLoaded: true}
  })),
  on(fromAccountActions.getRevenueCountSuccess, (state: State, props: any) => ({
    ...state,
    revenueCount: {data: props.data, isLoaded: true}
  })),
  on(fromAccountActions.getPaymentCountSuccess, (state: State, props: any) => ({
    ...state,
    paymentsCount: {data: props.data, isLoaded: true}
  })),

  on(fromAccountActions.captureImage, (state: State, props: any) => ({
    ...state,
    capturedImage: props.data
  })),
  on(fromAccountActions.selectStatusFilter, (state: State, props: any) => ({
    ...state,
    selectedFilter: props.data
  })),

  on(fromAccountActions.getSmsStatusSuccess, (state: State, props: any) => ({
    ...state,
    smsStatus: {data: props.data, isLoaded: true}
  })),


  on(fromAccountActions.getTerritoriesSuccess, (state: State, props: any) => ({
    ...state,
    territories: {data: props.data, isLoaded: true}
  })),
  on(fromAccountActions.getRiderTerritoriesSuccess, (state: State, props: any) => ({
    ...state,
    riderTerritories: {data: props.data, territory: props?.territory, isLoaded: true}
  })),
  on(fromAccountActions.getUserTerritoriesSuccess, (state: State, props: any) => ({
    ...state,
    userTerritories: {data: props.data, territory: props?.territory, isLoaded: true}
  })),
  on(fromAccountActions.getTerritoryAreaSucces, (state: State, props: any) => ({
    ...state,
    territoryArea:  {data: props.data, territory: props?.territory, isLoaded: true}
  })),

  on(fromAuthActions.autoLogout, (state: State, props) => initialState)
);
