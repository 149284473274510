import { createReducer, on } from '@ngrx/store';
import * as fromSharedActions from '../actions/shared.actions';
import * as fromAuthActions from '../actions/auth.actions';

export const featureKey = 'shared';

export interface State {
  showLoading: boolean;
  errorMessage: string;
  welcomeContent: {
    data: Array<any>,
    isLoaded: Boolean
  };
  fcmToken: string;
  unreadNotifications: {
    data: Array<any>,
    isLoaded: Boolean
  };
  getUserEvents: {
    data: Array<any>,
    isLoaded: Boolean
  };
  dashboardOrder: {
    data: Array<any>
  };
}

export const initialState: State = {
  showLoading: false,
  errorMessage: '',
  welcomeContent: {
    data: [],
    isLoaded: false
  },
  fcmToken: '',
  unreadNotifications: {
    data: [],
    isLoaded: false
  },
  getUserEvents: {
    data: [],
    isLoaded: false
  },
  dashboardOrder: {
    data: []
  }
};

export const reducer = createReducer(
  initialState,
  on(fromSharedActions.setLoadingSpinner, (state: State, props: any) => ({
    ...state,
    showLoading: props.status,
  })),
  on(fromSharedActions.setErrorMessage, (state: State, props: any) => ({
    ...state,
    errorMessage: props.message,
  })),
  on(fromSharedActions.setWelcomeContent, (state: State, props: any) => ({
    ...state,
    welcomeContent: { data: props.data, isLoaded: true },
  })),
  on(fromSharedActions.getUnreadNotificationSuccess, (state: State, props: any) => ({
    ...state,
    unreadNotifications: { data: props.data, isLoaded: true },
  })),
  on(fromSharedActions.failedWelcomeContent, (state: State, props: any) => ({
    ...state,
    welcomeContent: { ...state.welcomeContent, isLoaded: true },
  })),
  on(fromSharedActions.setFCMToken, (state: State, props: any) => ({
    ...state,
    fcmToken: props.data,
  })),
  on(fromSharedActions.getUserEventsSucces, (state: State, props: any) => ({
    ...state,
    getUserEvents: { data: props.data, isLoaded: true },
  })),
  on(fromSharedActions.reorderDashboardSuccess, (state: State, props: any) => ({
    ...state,
    dashboardOrder: { data: props.data },
  })),
  on(fromAuthActions.autoLogout, (state: State, props) => initialState)
);
