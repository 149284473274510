import { Injectable, NgZone } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import * as fromAddressActions from '../actions/address.actions';
import { AddressService } from 'src/app/services/address.service';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { APIResponse } from 'src/app/models/api-response.model';
import { RouterNavigatedAction, ROUTER_NAVIGATION } from '@ngrx/router-store';


@Injectable()
export class AddressEffects {

    getCountries$ = createEffect(() => {
        return this.actions$.pipe(
          ofType(ROUTER_NAVIGATION),
          filter((r : RouterNavigatedAction) => {
            return r.payload.routerState.url.startsWith('/home/complete-profile')
            || r.payload.routerState.url.startsWith('/home/settings/profile-information')
          }),
          mergeMap(() => {
            return this.addressService.getCountries().pipe(
              map((response: APIResponse) => {
                console.log('getCountries$', response);
                return fromAddressActions.getCountryListSuccess({ data: response.details});
              })
            );
          })
        );
      });

    getRefProvince$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromAddressActions.getProvinces),
            mergeMap((action) => {
                return this.addressService.refProvince(action?.data).pipe(
                  map((response: APIResponse) => {
                    console.log('getRefProvince$', response);
                    return fromAddressActions.getProvincesSuccess({ data: response.details});
                  })
                );
            })
        )
    });

    getCity$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromAddressActions.getCities),
            exhaustMap((action) => {
                return this.addressService.refCities(action?.data).pipe(
                    map((response: APIResponse) => {           
                        console.log('getCity$', response);
                        return fromAddressActions.getCitiesSuccess({ data: response.details});
                    })
                );
            })
        )
    });

    getBarangays$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromAddressActions.getBarangays),
            exhaustMap((action) => {
                return this.addressService.refBarangays(action?.data).pipe(
                    map((response: APIResponse) => {           
                        console.log('getBarangays$', response);
                        return fromAddressActions.getBarangaysSuccess({ data: response.details});
                    })
                );
            })
        )
    });

    constructor(
        private actions$: Actions,
        private addressService: AddressService,
        private store: Store<AppState>
    ) {}

}