<div class="container-fluid d-flex align-items-center justify-content-center">
	<div class="row">
		<div class="col-md-12 d-flex justify-content-center align-items-center">
			<img src="assets/img/abyan.png" alt="">
		</div>
		<div class="col-md-12 d-flex justify-content-center align-items-center">
			<h2>Login</h2>
		</div>
		<div class="col-md-12 d-flex justify-content-center align-items-center">
			<div class="login-container">
				<form [formGroup]="form">
					<div class="row">
						<div class="col-md-12 d-flex justify-content-center p-0">
							<mat-form-field appearance="outline">
								<mat-label>Email</mat-label>
								<input matInput  formControlName="email">
							</mat-form-field>
						</div>
						<div class="col-md-12 d-flex justify-content-center p-0">
							<mat-form-field appearance="outline">
								<mat-label>Password</mat-label>
								<input matInput type="password" formControlName="password">
							</mat-form-field>
						</div>
					</div>
	
					<div class="row">
						<div class="col-md-12 d-flex justify-content-center">
							<button mat-raised-button color="primary" (click)="login()" [disabled]="!form.valid">Login</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>