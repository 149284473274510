import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-re-assign-deliveries',
  templateUrl: './re-assign-deliveries.component.html',
  styleUrls: ['./re-assign-deliveries.component.scss']
})
export class ReAssignDeliveriesComponent implements OnInit {

  deliveries!: any;
  riders!: any;
  details!: any;

  isAllSelected = false;

  constructor(
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
    console.log(this.details)
    let tempArr: any = [];
    let x;
    this.deliveries.forEach((e) => {
      x = {...e, isSelected: false};
      tempArr.push(x)
    });
    this.deliveries = tempArr;
    console.log({tempArr});
  }

  checkUncheckAll(evt) {
    this.deliveries.forEach((c) => c.isSelected = evt.target.checked);
    console.log(this.deliveries);
  }

  selectIndividual(delivery ,evt){
    this.isAllSelected = false;
    this.deliveries.forEach((c) => {
      if(c?.referenceId == delivery?.referenceId){
        c.isSelected = evt.target.checked
      }
    });

    console.log(this.deliveries);
  }

  cancel(){
    this.modalService.hideReAssignDeliveries();
  }

  proceed(){
    let tempArr: any = [];
    this.deliveries.forEach((e) => {
      if(e?.isSelected){
        tempArr.push(e?.status?.deliveryId);
      }
    });

    if(tempArr.length > 0){
      var joinDeliveryIds = tempArr.join(',');
      const initialState = {
        isReAssign: true,
        riders: this.riders,
        details: {
          username: this.details?.username,
          delivery_ids: joinDeliveryIds.toString()
        }
      }

      this.modalService.assignDeliveries(initialState);
      this.modalService.hideReAssignDeliveries();
    }
  }

}
