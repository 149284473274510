import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AccountService } from 'src/app/services/account.service';
import { ModalService } from 'src/app/services/modal.service';
import { getRoles, getSmsStatus, getUsers } from 'src/app/store/actions/account.actions';
import { logIn } from 'src/app/store/actions/auth.actions';
import { setLoadingSpinner } from 'src/app/store/actions/shared.actions';
import { AppState } from 'src/app/store/app.state';
import { MustMatch } from 'src/app/validators/mustmatch.validator';

@Component({
  selector: 'app-update-sms-status',
  templateUrl: './update-sms-status.component.html',
  styleUrls: ['./update-sms-status.component.scss']
})
export class UpdateSmsStatusComponent implements OnInit {

  smsStatus!: any;
  deliveryStatuses!: any;

  form: FormGroup;

  emailPattern = /^([a-z0-9_\.\+-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/; // Uncommon Email Pattern

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private accountService: AccountService,
    private bsModalService: BsModalService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      statusText: ['', [Validators.required]],
      statusValue: ['', [Validators.required]],
      status: ['', [Validators.required]],
    });

    this.form.patchValue({
      statusText: this.smsStatus?.statusText,
      statusValue: this.smsStatus?.statusValue,
      status: this.smsStatus?.status
    })

    console.log(this.smsStatus);
  }

  updateSmsStatus(){
    const payload = {
      statusText: this.statusText?.value,
      statusValue: this.statusValue?.value,
      status: this.status?.value
    }

    console.log({payload})

    this.modalService.showLoadingSpinner();
    this.accountService.updateSmsStatus(payload, this.smsStatus?.id).toPromise().then((res) => {
      if(res){
        console.log({res});
        this.modalService.hideLoadingSpinner();
        this.modalService.hideUpdateSmsStatus();
        this.store.dispatch(getSmsStatus());
        this.modalService.successModal(`SMS Status details has been successfully updated.`);
      }
    }).catch((err) => {
      this.modalService.hideLoadingSpinner();
      this.modalService.errorModal(err?.error?.message);
    })
  }

  cancel(){
    this.modalService.hideUpdateSmsStatus();
  }

  get statusText(){
    return this.form.get('statusText');
  }

  get statusValue(){
    return this.form.get('statusValue');
  }

  get status(){
    return this.form.get('status');
  }
}
