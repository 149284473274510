
    <div class="row roadmap-type">
        <div class="col-md-12">
            <button>
                Exact dates
            </button>
        </div>
        <div class="col-md-12">
            <button>
                Months & Years
            </button>
        </div>
        <div class="col-md-12">
            <button>
                Quarterly
            </button>
        </div>
        <div class="col-md-12">
            <button>
                Yearly
            </button>
        </div>
        <hr>
        <div class="col-md-12" (click)="hide()">
            <button>
                Custom Label
            </button>
        </div>
    </div>