import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PagesModule } from './pages/pages.module';
import { SharedModule } from './shared/shared.module';
import { AuthModule } from './pages/auth/auth.module';
import { AuthGuard } from './guards/auth.guard';
import { HomeLayoutComponent } from './layout/home/home-layout/home-layout.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then( m => m.AuthModule)
  },
  {
    path: 'home',
    component: HomeLayoutComponent,
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomeModule),
    canActivate: [AuthGuard]
  },
];


@NgModule({
  imports: [
    PagesModule,
    SharedModule,
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule, AuthModule]
})
export class AppRoutingModule { }
