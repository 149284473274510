import { createReducer, on } from '@ngrx/store';
import * as fromAuthActions from '../actions/auth.actions';
import { UserSession } from 'src/app/models/user-session.model';

export const featureKey = 'auth';

export interface State {
  isAuthenticated: boolean;
  userSession: any;
}

export const initialState: State = {
  isAuthenticated: false,
  userSession: null
};

export const reducer = createReducer(
  initialState,
  on(fromAuthActions.signUpSuccess, (state: State, props: any) => ({
    ...state,
    userSession: props.tempUserSession
  })),
  on(fromAuthActions.verifyForgotPasswordSuccess, (state: State, props: any) => ({
    ...state,
    userSession: props.tempUserSession
  })),
  on(fromAuthActions.logInSuccess, (state: State, props: any) => ({
    ...state,
    isAuthenticated: props.data ? true : false,
    userSession: props.data
  })),
  on(fromAuthActions.autoLogout, (state: State, props) => initialState)
);
