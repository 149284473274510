<div class="grid">
	<div class="row p-3">
		<div class="col-md-12">
			<h3>Create Role</h3>
		</div>
		<div class="col-md-12">
			<div class="login-container">
				<form [formGroup]="form">
					<div class="row">
						<div class="col-md-12 d-flex justify-content-center p-0">
							<mat-form-field appearance="outline">
								<mat-label>Role Name</mat-label>
								<input matInput  formControlName="role">
							</mat-form-field>
						</div>
					</div>
	
					<div class="row">
						<div class="col-md-12 d-flex justify-content-center">
							<button class="btn btn-primary" mat-raised-button color="primary" (click)="login()" [disabled]="!form.valid">Create</button>
							<button mat-raised-button color="danger" (click)="cancel()" class="cancel">Cancel</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>