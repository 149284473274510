import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class AddressService {

  constructor(
    private http: HttpClient
  ) { }

  getCountries(): Observable<any> {
    return this.http
      .get<any>(environment.apiUrl + '/v2/public/get-refcountries');
  }

  refProvince(item: any): Observable<any> {
    return this.http
      .get<any>(environment.apiUrl + `/v2/public/get-refprovince?countryCode=${item}`);
  }

  refCities(item: any): Observable<any> {
    return this.http
      .get<any>(environment.apiUrl + `/v2/public/get-refcities?provCode=${item}`);
  }

  refBarangays(item: any): Observable<any> {
    return this.http
      .get<any>(environment.apiUrl + `/v2/public/get-refbrgy?citymunCode=${item}`);
  }
}
