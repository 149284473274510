<div class="grid">
	<div class="row p-3">
		<div class="col-md-12">
			<div class="list-group">
				<label class="list-group-item capitalize d-flex justify-content-between align-center">
					<div class="input-container">
						<input type="checkbox" name="list_name" value="m1" (change)="checkUncheckAll($event)" class="checkbox" [(ngModel)]="isAllSelected"/> 
						<strong>Select/ Unselect All</strong>
					</div>
				</label>
			</div>
		</div>
	</div>
	<div class="row p-3">
		<div class="col-md-12">
			<div class="list-group">
				<label class="list-group-item capitalize d-flex justify-content-between align-center" *ngFor="let item of deliveries">
					<div class="input-container">
						<input type="checkbox" class="checkbox" [checked]="item.isSelected" name="list_name" value="{{item.status?.id}}" (change)="selectIndividual(item, $event)"/>
						{{item.referenceId ? item.referenceId : item.requestId}}
					</div>
				</label>
			</div>
		</div>
	</div>

	<div class="row row-buttons">
		<div class="col-md-12 d-flex justify-content-center">
			<button class="btn btn-primary" mat-raised-button color="primary" (click)="proceed()">Proceed</button>
			<button mat-raised-button color="danger" class="cancel" (click)="cancel()">Cancel</button>
		</div>
	</div>
</div>

<!-- <div class="container">
	<div class="text-center">
		<div class="row">
			<div class="col-md-6">
			<ul class="list-group">
					<li class="list-group-item">
						<input type="checkbox" name="list_name" value="m1" (change)="checkUncheckAll($event)"/> <strong>Select/ Unselect All</strong>
					</li>
				</ul>
				<ul class="list-group">
					<li class="list-group-item" *ngFor="let item of deliveries">
						<input type="checkbox" [checked]="item.isSelected" name="list_name" value="{{item.status?.id}}" (change)="selectIndividual(item, $event)"/>
						{{item.referenceId}}
					</li>
				</ul>
			</div>
		</div>
	</div>

	<div class="row row-buttons">
		<div class="col-md-12 d-flex justify-content-center">
			<button class="btn btn-primary" mat-raised-button color="primary" (click)="proceed()">Proceed</button>
			<button mat-raised-button color="danger" class="cancel" (click)="cancel()">Cancel</button>
		</div>
	</div>
</div> -->