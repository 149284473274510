import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app.state';
import { UserSession } from '../models/user-session.model';
import { OfflineStorageService } from './offline-storage.service';

const USER_SESSION = 'userSession';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  // Init with null to filter out the first value in a guard!
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  timeoutInterval: any;

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private offlineStorageService: OfflineStorageService
  ) { }

  logIn(item: any): Observable<any> {
    return this.http
      .post<any>(environment.apiUrl + '/api/v1/auth/login-admin', JSON.stringify(item));
  }

  register(item: any): Observable<any> {
    return this.http
      .post(environment.apiUrl + '/api/v1/auth/register-admin', JSON.stringify(item));
  }

  formatUser(data: any) {
    const userSession = new UserSession(
      data.accessToken, // idToken
    );
    return userSession;
  }

  async setUserSession(userSession: UserSession) {
    this.offlineStorageService.setObject(USER_SESSION, JSON.stringify(userSession));
    // this.runTimeoutInterval(userSession);
  }

  // runTimeoutInterval(userSession: UserSession) {
  //   const todayDate = new Date().getTime();
  //   const expirationDate = userSession.expiration_date.getTime();
  //   const timeInterval = expirationDate - todayDate;
  //   console.log({timeInterval});
  //   this.timeoutInterval = setTimeout(() => {
  //     window.dispatchEvent(new CustomEvent('auth:timeout', { detail: true}));  
  //   }, timeInterval);
  // }

  async getUserSession() {
    const userSessionLocal: any = await this.offlineStorageService.getObject(USER_SESSION);
    console.log({userSessionLocal: JSON.parse(userSessionLocal)})
    const parsedSession = JSON.parse(userSessionLocal);
    if (parsedSession) {
      const userSession = new UserSession(
        parsedSession.accessToken,
      );
      return userSession;
    }
    return null;
  }


  async logout() {
    await this.offlineStorageService.removeItem(USER_SESSION);
    if (this.timeoutInterval) {
      clearTimeout(this.timeoutInterval);
      this.timeoutInterval = null;
    }
  }
}
