import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AccountService } from 'src/app/services/account.service';
import { ModalService } from 'src/app/services/modal.service';
import { SharedService } from 'src/app/services/shared.service';
import { getRoles } from 'src/app/store/actions/account.actions';
import { setLoadingSpinner } from 'src/app/store/actions/shared.actions';
import { AppState } from 'src/app/store/app.state';

@Component({
  selector: 'app-assign-permission',
  templateUrl: './assign-permission.component.html',
  styleUrls: ['./assign-permission.component.scss']
})
export class AssignPermissionComponent implements OnInit {

  role!: any;
  permissions!: any
  title!: any

  selectedPermissions: any = [];
  permissionArr: any = [];

  processing: any = true;

  constructor(
    private store: Store<AppState>,
    private accountService: AccountService,
    private bsModalService: BsModalService,
    private sharedService: SharedService,
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
    this.checkExistingPermissions()
    console.log(this.selectedPermissions)
  }

  proceed(){
    const payload = {
      role: this.role?.name,
      permissions: this.selectedPermissions
    }

    console.log({payload})
    this.modalService.showLoadingSpinner();
    if(this.title == 'Assign'){
      this.accountService.assignPermissionToRole(payload).toPromise().then((res) => {
        if(res){
          console.log({res});
          this.modalService.hideLoadingSpinner();
          this.modalService.hideAssignPermission();
          this.modalService.successModal(`Successfully added permission(s) to ${this.role?.name}`)
          this.store.dispatch(getRoles());
        }
      }).catch((err) => {
        console.log({err});
        this.modalService.hideLoadingSpinner();
        this.modalService.errorModal(err?.error?.message);
      })
    }
    else{
      this.accountService.removePermissionsToRole(payload).then((res) => {
        if(res){
          console.log({res});
          this.modalService.hideLoadingSpinner();
          this.modalService.hideRemovePerimission();
          this.modalService.successModal(`Successfully removed permission(s) to ${this.role?.name}`)
          this.store.dispatch(getRoles());
        }
      }).catch((err) => {
        console.log({err});
        this.modalService.hideLoadingSpinner();
        this.modalService.errorModal(err?.error?.message);
      })
    }
  }

  cancel(){
    this.bsModalService.hide();
  }

  onChange(value: any): void {
    const filterPerm = this.selectedPermissions?.filter((e: any) => e?.name == value?.name)[0];
    if(filterPerm){
      this.selectedPermissions = this.selectedPermissions.filter((item: any) => item?.name !== value?.name);
    } else {
      this.selectedPermissions.push(value);
    }
    console.log(this.selectedPermissions);
  }

  checkExistingPermissions(){
    let existingPermissions: any = [];
    if(this.role?.permissions?.length > 0){
      this.permissions.forEach((perm: any) => {
        let x;
        const filterPerm = this.role?.permissions?.filter((e: any) => e?.name == perm?.name)[0];
        if(filterPerm){
          x = {...perm, checked: true};
        }
        else{
          x = {...perm};
        }

        existingPermissions.push(x);
      })
      this.permissionArr = existingPermissions;
    }
    else{
      this.permissionArr = this.permissions;
    }
  }
}
