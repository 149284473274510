import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { ModalService } from 'src/app/services/modal.service';
import { captureImage } from 'src/app/store/actions/account.actions';
import { AppState } from 'src/app/store/app.state';

@Component({
  selector: 'app-capture-image',
  templateUrl: './capture-image.component.html',
  styleUrls: ['./capture-image.component.scss']
})
export class CaptureImageComponent implements OnInit {

  showWebcam = true;
  allowCameraSwitch = true;
  multipleWebcamsAvailable = false;
  deviceId: any;
  facingMode: string = 'environment';
  messages: any[] = [];

  webcamImage: WebcamImage;
  blobImage: any
  trigger: Subject<void> = new Subject<void>();
  nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();

  constructor(
    private modalService: ModalService,
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.messages.push(error);
    // if (error.mediaStreamError && error.mediaStreamError.name === 'NotAllowedError') {
    //   this.addMessage('User denied camera access');
    // }
  }

  public showNextWebcam(directionOrDeviceId: boolean|string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.addMessage('Received webcam image');
    console.log(webcamImage);
    var blobImage = this.dataURLtoBlob(webcamImage.imageAsDataUrl);
    this.blobImage = blobImage
    this.webcamImage = webcamImage;
  }

  public cameraWasSwitched(deviceId: string): void {
    this.addMessage('Active device: ' + deviceId);
    this.deviceId = deviceId;
    this.readAvailableVideoInputs();
  }

  addMessage(message: any): void {
    console.log(message);
    this.messages.unshift(message);
  }

  get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  get nextWebcamObservable(): Observable<boolean|string> {
    return this.nextWebcam.asObservable();
  }

  get videoOptions(): MediaTrackConstraints {
    const result: MediaTrackConstraints = {};
    if (this.facingMode && this.facingMode !== '') {
      result.facingMode = { ideal: this.facingMode };
    }

    return result;
  }

  private readAvailableVideoInputs() {
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });
  }

  dataURLtoBlob(dataurl: any) { 
    if(dataurl){
      const split = dataurl.split(',');
      const type = split[0].replace('data:', '').replace(';base64', '');
      console.log({type});
      const byteString = atob(split[1]);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i += 1) {
          ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], {type});
    }
    else{
      return false
    }
  }

  close(){
    this.modalService.hideCameraModal();
    const capturedData = {
      blobImage: this.blobImage,
      base64String: this.webcamImage.imageAsDataUrl
    }
    this.store.dispatch(captureImage({data: capturedData}))
  }

  dismiss(){
    this.modalService.hideCameraModal();
  }

}
