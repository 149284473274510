import { AuthEffects } from './auth.effects';
import { SharedEffects } from './shared.effects';
import { AccountEffects } from './account.effects';
import { AddressEffects } from './address.effects';
import { ImageEffects } from './image.effects';

export const effects: any[] = [
    AuthEffects, 
    SharedEffects, 
    AccountEffects, 
    AddressEffects,
    ImageEffects
];
