<div class="grid">
	<div class="row p-3">
		<div class="col-md-12">
			<h3>Update Rider Status</h3>
		</div>
		<div class="col-md-12">
			<div class="login-container">
				<form [formGroup]="form">
					<div class="row">
						<div class="col-md-12 d-flex justify-content-center p-0">
							<mat-form-field appearance="outline">
								<mat-label>Name</mat-label>
								<input matInput formControlName="name" readonly>
							</mat-form-field>
						</div>
					</div>

					<div class="row">
						<div class="col-md-12 d-flex justify-content-center p-0">
							<mat-form-field appearance="outline">
								<mat-label>Username</mat-label>
								<input matInput formControlName="username" readonly>
							</mat-form-field>
						</div>
					</div>

					<div class="row">
						<div class="col-md-12 d-flex justify-content-center p-0">
							<mat-form-field appearance="outline">
								<mat-label>Status</mat-label>
								<select matNativeControl formControlName="status">
									<option value="new">New</option>
									<option value="active">Active</option>
                                    <option value="inactive">Inactive</option>
									<option value="sick">Sick</option>
                                    <option value="vacation">Vacation</option>
									<option value="terminated">Terminated</option>
								</select>
							</mat-form-field>
						</div>
					</div>
	
					<div class="row">
						<div class="col-md-12 d-flex justify-content-center">
							<button class="btn btn-primary" mat-raised-button color="primary" (click)="updateUser()" [disabled]="!form.valid">Update</button>
							<button mat-raised-button color="danger" (click)="cancel()" class="cancel">Cancel</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>