<div class="grid">
	<div class="row p-3">
		<div class="col-md-12">
			<h3>Update SMS Status</h3>
		</div>
		<div class="col-md-12">
			<div class="login-container">
				<form [formGroup]="form">
					<div class="row">
						<div class="col-md-12 d-flex justify-content-center p-0">
							<mat-form-field appearance="outline">
								<mat-label>Status Text</mat-label>
								<input matInput formControlName="statusText">
							</mat-form-field>
						</div>
					</div>

					<div class="row">
						<div class="col-md-12 d-flex justify-content-center p-0">
							<mat-form-field appearance="outline">
								<mat-label>Status Value</mat-label>
								<select matNativeControl formControlName="statusValue">
									<option value="{{ item?.value }}" *ngFor="let item of deliveryStatuses">{{ item?.code }}</option>
									<option value="inactive">Inactive</option>
								</select>
							</mat-form-field>
						</div>
					</div>

					<div class="row">
						<div class="col-md-12 d-flex justify-content-center p-0">
							<mat-form-field appearance="outline">
								<mat-label>Status</mat-label>
								<select matNativeControl formControlName="status">
									<option value="active">Active</option>
									<option value="inactive">Inactive</option>
								</select>
							</mat-form-field>
						</div>
					</div>
	
					<div class="row">
						<div class="col-md-12 d-flex justify-content-center">
							<button class="btn btn-primary" mat-raised-button color="primary" (click)="updateSmsStatus()" [disabled]="!form.valid">Update</button>
							<button mat-raised-button color="danger" (click)="cancel()" class="cancel">Cancel</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>