import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AccountService } from 'src/app/services/account.service';
import { ModalService } from 'src/app/services/modal.service';
import { createRiderTerritories, createTerritories, getRoles, getSmsStatus, getUsers, updateRiderTerritories } from 'src/app/store/actions/account.actions';
import { logIn } from 'src/app/store/actions/auth.actions';
import { setLoadingSpinner } from 'src/app/store/actions/shared.actions';
import { AppState } from 'src/app/store/app.state';
import { MustMatch } from 'src/app/validators/mustmatch.validator';

@Component({
  selector: 'app-update-rider-territory',
  templateUrl: './update-rider-territory.component.html',
  styleUrls: ['./update-rider-territory.component.scss']
})
export class UpdateRiderTerritoryComponent implements OnInit {

  riders!: any;
  territory!: any;

  riderTerritory!: any;

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private accountService: AccountService,
    private bsModalService: BsModalService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    console.log(this.riderTerritory)
    this.form = this.fb.group({
      riderId: ['', [Validators.required]],
      areaStatus: ['', [Validators.required]]
    });

    this.form.patchValue({
      riderId: this.riderTerritory?.rider?.id,
      areaStatus: this.riderTerritory?.areaStatus
    })
  }

  updateRiderTerritory(){
    const payload = {
      riderId: this.riderId?.value,
      areaStatus: this.areaStatus?.value
    }

    console.log({payload})

    this.modalService.showLoadingSpinner();
    this.store.dispatch(updateRiderTerritories({data: payload, territory: this.territory, id: this.riderTerritory?.id}));
  }

  cancel(){
    this.modalService.hideUpdateRiderTerritory();
  }

  get riderId(){
    return this.form.get('riderId');
  }

  get areaStatus(){
    return this.form.get('areaStatus');
  }
}
