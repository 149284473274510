/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpContextToken,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, exhaustMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app.state';
import { getUserSession } from '../store/selectors/auth.selectors';
import { autoLogout } from '../store/actions/auth.actions';

export const SKIP = new HttpContextToken(() => false);
export const BEARER_ONLY = new HttpContextToken(() => false);
export const BYPASS_HEAD = new HttpContextToken(() => false);

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private store: Store<AppState>) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.store.select(getUserSession).pipe(
      take(1),
      exhaustMap((userSession) => {
        const token = userSession?.accessToken;
        if (request.context.get(BYPASS_HEAD) === true) {
          return next.handle(request);
        } 
        
        else if(request.context.get(BEARER_ONLY) === true) {
          const modifiedReq = request.clone({
            setHeaders: {             
              'Authorization': `Bearer ${token}`,
            },
          });
          return next.handle(modifiedReq);
        }
        
        if(!token || request.context.get(SKIP) === true) {
          const modifiedReq = request.clone({
            setHeaders: {
              'Content-Type' : 'application/json; charset=utf-8',
              'Accept'       : 'application/json'
            },
          });
          return next.handle(modifiedReq);
        }  
        else {
          const modifiedReq = request.clone({
            setHeaders: {
              'Content-Type' : 'application/json; charset=utf-8',
              'Accept'       : 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          });
          return next.handle(modifiedReq);
        }
      })      
    );
  }
}
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private store: Store<AppState>) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((response) => {
        if (response.status === 401) {
          window.dispatchEvent(new CustomEvent('auth:timeout', { detail: true}));  
        }
        return throwError(response);
      })
    );
  }
}
