import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AccountService } from 'src/app/services/account.service';
import { ModalService } from 'src/app/services/modal.service';
import { getRoles, getUsers } from 'src/app/store/actions/account.actions';
import { logIn } from 'src/app/store/actions/auth.actions';
import { setLoadingSpinner } from 'src/app/store/actions/shared.actions';
import { AppState } from 'src/app/store/app.state';
import { MustMatch } from 'src/app/validators/mustmatch.validator';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {
  form = new FormGroup({});

  emailPattern = /^([a-z0-9_\.\+-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/; // Uncommon Email Pattern

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private accountService: AccountService,
    private bsModalService: BsModalService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      password: ['', [Validators.required]],
    });
  }

  createUser(){
    const payload = {
      name: this.name?.value,
      email: this.email?.value,
      password: this.password?.value,
    }

    console.log({payload})

    this.modalService.showLoadingSpinner();
    this.accountService.createUser(payload).toPromise().then((res) => {
      if(res){
        console.log({res});
        this.modalService.hideLoadingSpinner();
        this.modalService.hideCreateUser();
        this.modalService.successModal(`User has been successfully created.`)
        this.store.dispatch(getUsers());
      }
    }).catch((err) => {
      this.modalService.hideLoadingSpinner();
      this.modalService.errorModal(err?.error?.message);
    })
  }

  cancel(){
    this.bsModalService.hide();
  }

  get name(){
    return this.form.get('name');
  }

  get email(){
    return this.form.get('email');
  }

  get password(){
    return this.form.get('password');
  }

  get status(){
    return this.form.get('status');
  }
}
