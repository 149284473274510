import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { TopnavComponent } from './components/theme/topnav/topnav.component';
// import { AsidenavComponent } from './components/theme/asidenav/asidenav.component';
// import { FooterComponent } from './components/theme/footer/footer.component';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RoadmapTypeComponent } from './modals/roadmap-type/roadmap-type.component';
import { CreateRoleComponent } from './modals/create-role/create-role.component';
import { MaterialModule } from '../material/material.module';
import { AssignPermissionComponent } from './modals/assign-permission/assign-permission.component';
import { LoadingSpinnerComponent } from './modals/loading-spinner/loading-spinner.component';
import { AssignRoleComponent } from './modals/assign-role/assign-role.component';
import { SuccessComponent } from './modals/success/success.component';
import { ErrorComponent } from './modals/error/error.component';
import { RemoveRoleConfirmComponent } from './modals/remove-role-confirm/remove-role-confirm.component';
import { AssignDeliveriesComponent } from './modals/assign-deliveries/assign-deliveries.component';
import { UpdateUserComponent } from './modals/update-user/update-user.component';
import { CreateUserComponent } from './modals/create-user/create-user.component';
import { UpdateRiderStatusComponent } from './modals/update-rider-status/update-rider-status.component';
import { ScannerComponent } from './modals/scanner/scanner.component';
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { CaptureImageComponent } from './modals/capture-image/capture-image.component';
import {WebcamModule} from 'ngx-webcam';
import { HeaderComponent } from './components/header/header.component';
import { SelectFilterComponent } from './modals/select-filter/select-filter.component';
import { DeliveryListComponent } from './modals/delivery-list/delivery-list.component';
import { ViewDeliveryDetailsComponent } from './modals/view-delivery-details/view-delivery-details.component';
import { UpdateSmsStatusComponent } from './modals/update-sms-status/update-sms-status.component';
import { CreateSmsStatusComponent } from './modals/create-sms-status/create-sms-status.component';
import { RemoveSmsStatusConfirmComponent } from './modals/remove-sms-status-confirm/remove-sms-status-confirm.component';
import { UploadDayonFileComponent } from './modals/upload-dayon-file/upload-dayon-file.component';
import { CreateTerritoryComponent } from './modals/create-territory/create-territory.component';
import { UpdateTerritoryComponent } from './modals/update-territory/update-territory.component';
import { CreateRiderTerritoryComponent } from './modals/create-rider-territory/create-rider-territory.component';
import { UpdateRiderTerritoryComponent } from './modals/update-rider-territory/update-rider-territory.component';
import { CreateUserTerritoryComponent } from './modals/create-user-territory/create-user-territory.component';
import { UpdateUserTerritoryComponent } from './modals/update-user-territory/update-user-territory.component';
import { CreateTerritoryAreaComponent } from './modals/create-territory-area/create-territory-area.component';
import { UpdateTerritoryAreaComponent } from './modals/update-territory-area/update-territory-area.component';
import { ReAssignDeliveriesComponent } from './modals/re-assign-deliveries/re-assign-deliveries.component';
@NgModule({
  declarations: [
    RoadmapTypeComponent,
    CreateRoleComponent,
    AssignPermissionComponent,
    LoadingSpinnerComponent,
    AssignRoleComponent,
    SuccessComponent,
    ErrorComponent,
    RemoveRoleConfirmComponent,
    AssignDeliveriesComponent,
    UpdateUserComponent,
    CreateUserComponent,
    UpdateRiderStatusComponent,
    ScannerComponent,
    CaptureImageComponent,
    HeaderComponent,
    SelectFilterComponent,
    DeliveryListComponent,
    ViewDeliveryDetailsComponent,
    UpdateSmsStatusComponent,
    CreateSmsStatusComponent,
    RemoveSmsStatusConfirmComponent,
    UploadDayonFileComponent,
    CreateTerritoryComponent,
    UpdateTerritoryComponent,
    CreateRiderTerritoryComponent,
    UpdateRiderTerritoryComponent,
    CreateUserTerritoryComponent,
    UpdateUserTerritoryComponent,
    CreateTerritoryAreaComponent,
    UpdateTerritoryAreaComponent,
    ReAssignDeliveriesComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    NgbModule,
    MaterialModule,
    NgxScannerQrcodeModule,
    WebcamModule
  ],
  exports: [
    // TopnavComponent,
    // AsidenavComponent,
    // FooterComponent,
    LoadingSpinnerComponent,
    HeaderComponent
  ],
})
export class SharedModule {}
