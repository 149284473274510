import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AccountService } from 'src/app/services/account.service';
import { ModalService } from 'src/app/services/modal.service';
import { createTerritories, getRoles, getSmsStatus, getUsers } from 'src/app/store/actions/account.actions';
import { logIn } from 'src/app/store/actions/auth.actions';
import { setLoadingSpinner } from 'src/app/store/actions/shared.actions';
import { AppState } from 'src/app/store/app.state';
import { MustMatch } from 'src/app/validators/mustmatch.validator';

@Component({
  selector: 'app-create-territory',
  templateUrl: './create-territory.component.html',
  styleUrls: ['./create-territory.component.scss']
})
export class CreateTerritoryComponent implements OnInit {

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private accountService: AccountService,
    private bsModalService: BsModalService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      territoryName: ['', [Validators.required]],
      territoryStatus: ['', [Validators.required]]
    });
  }

  updateSmsStatus(){
    const payload = {
      territoryName: this.territoryName?.value,
      territoryStatus: this.territoryStatus?.value
    }

    console.log({payload})

    this.modalService.showLoadingSpinner();
    this.store.dispatch(createTerritories({data: payload}));
  }

  cancel(){
    this.modalService.hideCreateTerritory();
  }

  get territoryName(){
    return this.form.get('territoryName');
  }

  get territoryStatus(){
    return this.form.get('territoryStatus');
  }
}
