<div class="container">
	<mat-accordion class="example-headers-align" multi>
    <mat-expansion-panel *ngFor="let item of deliveries" class="mt-2">
      <mat-expansion-panel-header>
        <mat-panel-title> Reference ID: {{ item?.referenceId }} </mat-panel-title>
        <!-- <mat-panel-description>
          Type your name and age
          <mat-icon>account_circle</mat-icon>
        </mat-panel-description> -->
      </mat-expansion-panel-header>
  
      <div class="row">
        <div class="col-md-6">
            <span class="t-fs12">{{ item?.address?.address1 }}</span>
        </div>
        <div class="col-md-6">
            <span class="t-fs12">Target pickup date: {{ formatDate(item?.targetPickup) }}</span>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

	<div class="buttons mt-3">
		<div class="row">
			<div class="col-md-12 d-flex justify-content-center">
				<button class="btn btn-primary" (click)="close()">Close</button>
			</div>
		</div>
	</div>
</div>