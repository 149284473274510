import { Injectable, NgZone } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, exhaustMap, catchError, tap, withLatestFrom, mergeMap, filter, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import * as fromAccountActions from '../actions/account.actions';
import { AccountService } from 'src/app/services/account.service';
import { RouterNavigatedAction, ROUTER_NAVIGATION } from '@ngrx/router-store';
import { APIResponse } from 'src/app/models/api-response.model';
import { AuthService } from '../../services/auth.service';
import { SharedService } from 'src/app/services/shared.service';
import { OfflineStorageService } from 'src/app/services/offline-storage.service';
import { failedTransaction, setLoadingSpinner } from '../actions/shared.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { ErrorService } from 'src/app/services/error.service';
import { ModalService } from 'src/app/services/modal.service';
import { Router } from '@angular/router';
@Injectable()
export class AccountEffects {
  getUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccountActions.getUserProfile),
      mergeMap(() => {
        return this.accountService.userProfile().pipe(
          map((response: APIResponse) => {           
            console.log('userProfile$', response);
            return fromAccountActions.updateUserProfileSuccess({ data: response.details});
          })
        );
      })
    )
  );

  userProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      filter((r : RouterNavigatedAction) => {
        return r.payload.routerState.url.startsWith('/home/dashboard');
      }),
      mergeMap(() => {
        return this.accountService.userProfile().pipe(
          map((response: APIResponse) => {
            console.log('userProfile$', response);
            return fromAccountActions.updateUserProfileSuccess({ data: response?.details});
          })
        );
      })
    );
  });

  getUnassignedDeliveries$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      filter((r : RouterNavigatedAction) => {
        return r.payload.routerState.url.startsWith('/home/unassigned-deliveries');
      }),
      mergeMap(() => {
        return this.accountService.getUnassignedDeliveries().pipe(
          map((response: APIResponse) => {
            console.log('getUnassignedDeliveries$', response?.details);
            return fromAccountActions.getUnassignedDeliveriesSuccess({ data: response?.details});
          })
        );
      })
    );
  });

  reloadUnassignedDeliveries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccountActions.getUnassignedDeliveries),
      mergeMap(() => {
        return this.accountService.getUnassignedDeliveries().pipe(
          map((response: APIResponse) => {           
            console.log('getUnassignedDeliveries$', response);
            return fromAccountActions.getUnassignedDeliveriesSuccess({ data: response.details});
          })
        );
      })
    )
  );

  getRiders$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      filter((r : RouterNavigatedAction) => {
        return r.payload.routerState.url.startsWith('/home/unassigned-deliveries')
        || r.payload.routerState.url.startsWith('/home/assigned-deliveries')
        || r.payload.routerState.url.startsWith('/home/riders')
        || r.payload.routerState.url.startsWith('/home/rider-territories');
      }),
      mergeMap(() => {
        return this.accountService.getRiders().pipe(
          map((response: APIResponse) => {
            console.log('getRiders$', response?.details);
            return fromAccountActions.getRidersSuccess({ data: response.details});
          })
        );
      })
    );
  });

  getDeliveryStatuses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      filter((r : RouterNavigatedAction) => {
        return r.payload.routerState.url.startsWith('/home/dashboard')
        || r.payload.routerState.url.startsWith('/home/deliveries') ;
      }),
      mergeMap(() => {
        return this.accountService.getDeliveryStatuses().pipe(
          map((response: APIResponse) => {
            console.log('getDeliveryStatuses$', response?.details);
            return fromAccountActions.getDeliveryStatusesSuccess({ data: response.details});
          })
        );
      })
    );
  });

  getAssignedDeliveries$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      filter((r : RouterNavigatedAction) => {
        return r.payload.routerState.url.startsWith('/home/assigned-deliveries');
      }),
      mergeMap(() => {
        return this.accountService.getAssignedDeliveries().pipe(
          map((response: APIResponse) => {
            console.log('getAssignedDeliveries$', response?.details);
            return fromAccountActions.getAssignedDeliveriesSuccess({ data: response.details});
          })
        );
      })
    );
  });

  reloadAssignedDeliveries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccountActions.getAssignedDeliveries),
      mergeMap(() => {
        return this.accountService.getAssignedDeliveries().pipe(
          map((response: APIResponse) => {           
            console.log('reloadAssignedDeliveries$', response);
            return fromAccountActions.getAssignedDeliveriesSuccess({ data: response.details});
          })
        );
      })
    )
  );

  reloadRiderList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccountActions.getRiders),
      mergeMap(() => {
        return this.accountService.getRiders().pipe(
          map((response: APIResponse) => {           
            console.log('reloadRiderList$', response?.details);
            return fromAccountActions.getRidersSuccess({ data: response.details});
          })
        );
      })
    )
  );

  getRoles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      filter((r : RouterNavigatedAction) => {
        return r.payload.routerState.url.startsWith('/home/roles') 
        || r.payload.routerState.url.startsWith('/home/users');
      }),
      mergeMap(() => {
        return this.accountService.getRoles().pipe(
          map((response: APIResponse) => {
            console.log('getRoles$', response);
            return fromAccountActions.getRolesSuccess({ data: response?.details});
          })
        );
      })
    );
  });

  reloadRolesList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccountActions.getRoles),
      mergeMap(() => {
        return this.accountService.getRoles().pipe(
          map((response: APIResponse) => {           
            console.log('reloadRolesList$', response?.details);
            return fromAccountActions.getRolesSuccess({ data: response.details});
          })
        );
      })
    )
  );

  getPermissions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      filter((r : RouterNavigatedAction) => {
        return r.payload.routerState.url.startsWith('/home/roles');
      }),
      mergeMap(() => {
        return this.accountService.getPermissions().pipe(
          map((response: APIResponse) => {
            console.log('getPermissions$', response);
            return fromAccountActions.getPermissionsSuccess({ data: response?.details});
          })
        );
      })
    );
  });

  reloadPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccountActions.getRoles),
      mergeMap(() => {
        return this.accountService.getPermissions().pipe(
          map((response: APIResponse) => {           
            console.log('reloadPermissions$', response?.details);
            return fromAccountActions.getPermissionsSuccess({ data: response.details});
          })
        );
      })
    )
  );

  getUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      filter((r : RouterNavigatedAction) => {
        return r.payload.routerState.url.startsWith('/home/users')
        || r.payload.routerState.url.startsWith('/home/user-territories');
      }),
      mergeMap(() => {
        return this.accountService.getUsers().pipe(
          map((response: APIResponse) => {
            console.log('getUsers$', response);
            return fromAccountActions.getUsersSuccess({ data: response?.details});
          })
        );
      })
    );
  });

  reloadUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccountActions.getUsers),
      mergeMap(() => {
        return this.accountService.getUsers().pipe(
          map((response: APIResponse) => {           
            console.log('reloadUsers$', response?.details);
            return fromAccountActions.getUsersSuccess({ data: response.details});
          })
        );
      })
    )
  );

  updateRiderStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccountActions.updateRiderStatus),
      exhaustMap((action) =>
        this.accountService.updateRiderStatus(action?.data).pipe(
          map((response: any) => {
            console.log('updateRiderStatus$', response);
            const token = response?.details;
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return fromAccountActions.updateRiderStatusSuccess({ data: response?.details });
          }),
          catchError((response) => {
            console.error('updateRiderStatus$', response);
            this.store.dispatch(setLoadingSpinner({ status: false }));
            const error = {
              status: response.status,
              error: this.errorService.getErrorMessage(response)
            };
            return of(failedTransaction({ data: error}));
          })
        )
      )
    )
  );

  updateRiderStatusSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAccountActions.updateRiderStatusSuccess),
        tap(async (action) => {
          this.store.dispatch(fromAccountActions.getRiders());
          console.log('updateRiderStatusSuccess$', action);
        })
      ),
    { dispatch: false }
  );

  assignDelivery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccountActions.assignDelivery),
      exhaustMap((action) =>
        this.accountService.assignDelivery(action?.data).pipe(
          map((response: any) => {
            console.log('assignDelivery$', response);
            const token = response?.details;
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return fromAccountActions.assignDeliverySuccess({ data: response?.details });
          }),
          catchError((response) => {
            console.error('assignDelivery$', response);
            this.store.dispatch(setLoadingSpinner({ status: false }));
            const error = {
              status: response.status,
              error: this.errorService.getErrorMessage(response)
            };
            return of(failedTransaction({ data: error}));
          })
        )
      )
    )
  );

  assignDeliverySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAccountActions.assignDeliverySuccess),
        tap(async (action) => {
          console.log('assignDeliverySuccess$', action);
        })
      ),
    { dispatch: false }
  );

  sequenceDeliveries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccountActions.sequenceDeliveries),
      exhaustMap((action) =>
        this.accountService.sequenceDeliveries(action?.data).pipe(
          map((response: any) => {
            console.log('sequenceDeliveries$', response);
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return fromAccountActions.sequenceDeliveriesSuccess({ data: response?.details });
          }),
          catchError((response) => {
            console.error('sequenceDeliveries$', response);
            this.store.dispatch(setLoadingSpinner({ status: false }));
            const error = {
              status: response.status,
              error: this.errorService.getErrorMessage(response)
            };
            return of(failedTransaction({ data: error}));
          })
        )
      )
    )
  );

  sequenceDeliveriesSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAccountActions.sequenceDeliveriesSuccess),
        tap(async (action) => {
          
          console.log('sequenceDeliveriesSuccess$', action);
        })
      ),
    { dispatch: false }
  );

  getDeliveriesCount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      filter((r : RouterNavigatedAction) => {
        return r.payload.routerState.url.startsWith('/home/dashboard');
      }),
      mergeMap(() => {
        return this.accountService.getDeliveryCount().pipe(
          map((response: APIResponse) => {
            console.log('getDeliveriesCount$', response?.details);
            return fromAccountActions.getDeliveriesCountSuccess({ data: response.details});
          })
        );
      })
    );
  });

  getRidersCount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      filter((r : RouterNavigatedAction) => {
        return r.payload.routerState.url.startsWith('/home/dashboard');
      }),
      mergeMap(() => {
        return this.accountService.getRiderCount().pipe(
          map((response: APIResponse) => {
            console.log('getRidersCount$', response?.details);
            return fromAccountActions.getRidersCountSuccess({ data: response?.details});
          })
        );
      })
    );
  });

  getRevenueCount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      filter((r : RouterNavigatedAction) => {
        return r.payload.routerState.url.startsWith('/home/dashboard');
      }),
      mergeMap(() => {
        return this.accountService.getRevenueCount().pipe(
          map((response: APIResponse) => {
            console.log('getRevenueCount$', response?.details);
            return fromAccountActions.getRevenueCountSuccess({ data: response.details});
          })
        );
      })
    );
  });

  getPaymentCount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      filter((r : RouterNavigatedAction) => {
        return r.payload.routerState.url.startsWith('/home/dashboard');
      }),
      mergeMap(() => {
        return this.accountService.getPaymentCount().pipe(
          map((response: APIResponse) => {
            console.log('getPaymentCount$', response?.details);
            return fromAccountActions.getPaymentCountSuccess({ data: response.details});
          })
        );
      })
    );
  });

  reloadSmsStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccountActions.getSmsStatus),
      mergeMap(() => {
        return this.accountService.getSmsStatus().pipe(
          map((response: APIResponse) => {           
            console.log('reloadSmsStatus$', response?.details);
            return fromAccountActions.getSmsStatusSuccess({ data: response.details});
          })
        );
      })
    )
  );

  getSmsStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      filter((r : RouterNavigatedAction) => {
        return r.payload.routerState.url.startsWith('/home/dashboard');
      }),
      mergeMap(() => {
        return this.accountService.getSmsStatus().pipe(
          map((response: APIResponse) => {
            console.log('getSmsStatus$', response?.details);
            return fromAccountActions.getSmsStatusSuccess({ data: response.details});
          })
        );
      })
    );
  });

  manualReconciliation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccountActions.manualReconciliation),
      exhaustMap((action) =>
        this.accountService.manualReconciliation().pipe(
          map((response: any) => {
            console.log('manualReconciliation$', response);
            this.modalService.hideLoadingSpinner();
            this.modalService.hideUploadDayonFile();
            this.modalService.successModal('Manual Reconciliation Success.');
            return fromAccountActions.manualReconciliationSuccess({ data: response?.details });
          }),
          catchError((response) => {
            console.error('manualReconciliation$', response);
            this.store.dispatch(setLoadingSpinner({ status: false }));
            const error = {
              status: response.status,
              error: this.errorService.getErrorMessage(response)
            };
            return of(failedTransaction({ data: error}));
          })
        )
      )
    )
  );

  getTerritories$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      filter((r : RouterNavigatedAction) => {
        return r.payload.routerState.url.startsWith('/home/territories');
      }),
      mergeMap(() => {
        return this.accountService.getTerritories().pipe(
          map((response: APIResponse) => {
            console.log('getTerritories$', response?.details);
            return fromAccountActions.getTerritoriesSuccess({ data: response?.details});
          })
        );
      })
    );
  });

  reloadTerritories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccountActions.getTerritories),
      mergeMap(() => {
        return this.accountService.getTerritories().pipe(
          map((response: APIResponse) => {           
            console.log('reloadTerritories$', response?.details);
            return fromAccountActions.getTerritoriesSuccess({ data: response.details});
          })
        );
      })
    )
  );

  createTerritory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccountActions.createTerritories),
      exhaustMap((action) =>
        this.accountService.createTerritories(action?.data).pipe(
          map((response: any) => {
            console.log('createTerritory$', response);
            this.store.dispatch(fromAccountActions.getTerritories());
            this.modalService.hideLoadingSpinner();
            this.modalService.hideCreateTerritory();
            this.modalService.successModal('Territory has been successfully created.');
            return fromAccountActions.createTerritoriesSuccess({ data: response?.details });
          }),
          catchError((response) => {
            console.error('createTerritory$', response);
            this.modalService.hideLoadingSpinner();
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return of(failedTransaction({ data: response?.error?.message}));
          })
        )
      )
    )
  );

  updateTerritory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccountActions.updateTerritories),
      exhaustMap((action) =>
        this.accountService.updateTerritories(action?.data, action?.territoryId).pipe(
          map((response: any) => {
            console.log('updateTerritory$', response);
            this.store.dispatch(fromAccountActions.getTerritories());
            this.modalService.hideLoadingSpinner();
            this.modalService.hideUpdateTerritory();
            this.modalService.successModal('Territory has been successfully updated.');
            return fromAccountActions.updateTerritoriesSuccess({ data: response?.details });
          }),
          catchError((response) => {
            console.error('updateTerritory$', response);
            this.modalService.hideLoadingSpinner();
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return of(failedTransaction({ data: response?.error?.message}));
          })
        )
      )
    )
  );

  viewRiderTerritories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccountActions.getRiderTerritories),
      exhaustMap((action) =>
        this.accountService.getRiderTerritories(action?.territory?.id).pipe(
          map((response: any) => {
            console.log('viewRiderTerritories$', action?.territory);
            this.modalService.hideLoadingSpinner();
            this.router.navigateByUrl('/home/rider-territories')
            return fromAccountActions.getRiderTerritoriesSuccess({ data: response?.details, territory: action?.territory });
          }),
          catchError((response) => {
            console.error('viewRiderTerritories$', response);
            this.modalService.hideLoadingSpinner();
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return of(failedTransaction({ data: response?.error?.message}));
          })
        )
      )
    )
  );

  createRiderTerritory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccountActions.createRiderTerritories),
      exhaustMap((action) =>
        this.accountService.createRiderTerritories(action?.data, action?.territory?.id).pipe(
          map((response: any) => {
            console.log('createRiderTerritory$', response);
            this.store.dispatch(fromAccountActions.getRiderTerritories({territory: action?.territory}));
            this.modalService.hideLoadingSpinner();
            this.modalService.hideCreateRiderTerritory();
            this.modalService.successModal('Rider Territory has been successfully created.');
            return fromAccountActions.createRiderTerritoriesSuccess({ data: response?.details });
          }),
          catchError((response) => {
            console.error('createRiderTerritory$', response);
            this.modalService.hideLoadingSpinner();
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return of(failedTransaction({ data: response?.error?.message}));
          })
        )
      )
    )
  );

  updateRiderTerritory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccountActions.updateRiderTerritories),
      exhaustMap((action) =>
        this.accountService.updateRiderTerritories(action?.data, action?.territory?.id, action?.id).pipe(
          map((response: any) => {
            console.log('updateRiderTerritory$', response);
            this.store.dispatch(fromAccountActions.getRiderTerritories({territory: action?.territory}));
            this.modalService.hideLoadingSpinner();
            this.modalService.hideUpdateRiderTerritory();
            this.modalService.successModal('Rider Territory has been successfully created.');
            return fromAccountActions.updateUserTerritoriesSuccess({ data: response?.details });
          }),
          catchError((response) => {
            console.error('updateRiderTerritory$', response);
            this.modalService.hideLoadingSpinner();
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return of(failedTransaction({ data: response?.error?.message}));
          })
        )
      )
    )
  );

  getUserTerritories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccountActions.getUserTerritories),
      exhaustMap((action) =>
        this.accountService.getUserTerritories(action?.territory?.id).pipe(
          map((response: any) => {
            console.log('getUserTerritories$', response);
            this.modalService.hideLoadingSpinner();
            this.router.navigateByUrl('/home/user-territories')
            return fromAccountActions.getUserTerritoriesSuccess({ data: response?.details, territory: action?.territory });
          }),
          catchError((response) => {
            console.error('getUserTerritories$', response);
            this.modalService.hideLoadingSpinner();
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return of(failedTransaction({ data: response?.error?.message}));
          })
        )
      )
    )
  );

  createUserTerritory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccountActions.createUserTerritories),
      exhaustMap((action) =>
        this.accountService.createUserTerritories(action?.data, action?.territory?.id).pipe(
          map((response: any) => {
            console.log('createUserTerritories$', response);
            this.store.dispatch(fromAccountActions.getUserTerritories({territory: action?.territory}));
            this.modalService.hideLoadingSpinner();
            this.modalService.hideCreateUserTerritory();
            this.modalService.successModal('User Territory has been successfully created.');
            return fromAccountActions.createUserTerritoriesSuccess({ data: response?.details });
          }),
          catchError((response) => {
            console.error('createUserTerritory$', response);
            this.modalService.hideLoadingSpinner();
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return of(failedTransaction({ data: response?.error?.message}));
          })
        )
      )
    )
  );

  updateUserTerritory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccountActions.updateUserTerritories),
      exhaustMap((action) =>
        this.accountService.updateUserTerritories(action?.data, action?.territory?.id, action?.id).pipe(
          map((response: any) => {
            console.log('updateUserTerritories$', response);
            this.store.dispatch(fromAccountActions.getUserTerritories({territory: action?.territory}));
            this.modalService.hideLoadingSpinner();
            this.modalService.hideUpdateUserTerritory();
            this.modalService.successModal('User Territory has been successfully created.');
            return fromAccountActions.updateUserTerritoriesSuccess({ data: response?.details });
          }),
          catchError((response) => {
            console.error('updateUserTerritory$', response);
            this.modalService.hideLoadingSpinner();
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return of(failedTransaction({ data: response?.error?.message}));
          })
        )
      )
    )
  );

  getTerritoryArea$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccountActions.getTerritoryArea),
      exhaustMap((action) =>
        this.accountService.getTerritoryArea(action?.territory?.id).pipe(
          map((response: any) => {
            console.log('getTerritoryAreas$', response);
            this.modalService.hideLoadingSpinner();
            this.router.navigateByUrl('/home/territory-area')
            return fromAccountActions.getTerritoryAreaSucces({ data: response?.details, territory: action?.territory });
          }),
          catchError((response) => {
            console.error('getTerritoryAreas$', response);
            this.modalService.hideLoadingSpinner();
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return of(failedTransaction({ data: response?.error?.message}));
          })
        )
      )
    )
  );

  createTerritoryArea$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccountActions.createTerritoryArea),
      exhaustMap((action) =>
        this.accountService.createTerritoryArea(action?.data, action?.territory?.id).pipe(
          map((response: any) => {
            console.log('createTerritoryArea$', response);
            this.store.dispatch(fromAccountActions.getTerritoryArea({territory: action?.territory}));
            this.modalService.hideLoadingSpinner();
            this.modalService.hideCreateTerritoryArea();
            this.modalService.successModal('Territory Area has been successfully created.');
            return fromAccountActions.createTerritoryAreaSuccess({ data: response?.details });
          }),
          catchError((response) => {
            console.error('createTerritoryArea$', response);
            this.modalService.hideLoadingSpinner();
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return of(failedTransaction({ data: response?.error?.message}));
          })
        )
      )
    )
  );

  updateTerritoryArea$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccountActions.updateTerritoryArea),
      exhaustMap((action) =>
        this.accountService.updateTerritoryArea(action?.data, action?.territory?.id, action?.id).pipe(
          map((response: any) => {
            console.log('updateTerritoryArea$', response);
            this.store.dispatch(fromAccountActions.getTerritoryArea({territory: action?.territory}));
            this.modalService.hideLoadingSpinner();
            this.modalService.hideUpdateTerritoryArea();
            this.modalService.successModal('Territory Area has been successfully created.');
            return fromAccountActions.updateTerritoryAreaSuccess({ data: response?.details });
          }),
          catchError((response) => {
            console.error('updateTerritoryArea$', response);
            this.modalService.hideLoadingSpinner();
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return of(failedTransaction({ data: response?.error?.message}));
          })
        )
      )
    )
  );
  
  constructor(
    private actions$: Actions,
    private accountService: AccountService,
    private store: Store<AppState>,
    private errorService: ErrorService,
    private modalService: ModalService,
    private router: Router
  ) {}
}
