<div class="container">
    <div class="grid">
        <div class="row">
            <div class="col-md-12 text-center">
                <img src="assets/img/info-red.svg" alt="">
                <h2 class="m-0 mt-3">Warning!</h2>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 text-center">
                <h4 class="m-0 mt-2">You are about to delete {{ role?.name }}. Please confirm deletetion.</h4>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 d-flex justify-content-center">
                <button mat-raised-button color="primary" class="btn-add mt-3 btn btn-primary" (click)="confirm()">
                    Proceed
                </button>
                <button mat-raised-button color="primary" class="btn-remove mt-3" (click)="dismiss()">
                    Cancel
                </button>
            </div>
        </div>
    </div>
</div>