import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AccountService } from 'src/app/services/account.service';
import { ModalService } from 'src/app/services/modal.service';
import { getRoles, getUsers } from 'src/app/store/actions/account.actions';
import { logIn } from 'src/app/store/actions/auth.actions';
import { setLoadingSpinner } from 'src/app/store/actions/shared.actions';
import { AppState } from 'src/app/store/app.state';
import { MustMatch } from 'src/app/validators/mustmatch.validator';

@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.scss']
})
export class UpdateUserComponent implements OnInit {

  user!: any;

  form = new FormGroup({});

  emailPattern = /^([a-z0-9_\.\+-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/; // Uncommon Email Pattern

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private accountService: AccountService,
    private bsModalService: BsModalService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      password: ['', new FormControl()],
      status: ['', [Validators.required]],
    });

    this.form.patchValue({
      name: this.user?.name,
      email: this.user?.email,
      status: this.user?.status
    })

    console.log(this.user);
  }

  updateUser(){
    const payload = {
      name: this.name?.value,
      email: this.email?.value,
      password: this.password?.value,
      status: this.status?.value
    }

    console.log({payload})

    this.modalService.showLoadingSpinner();
    this.accountService.updateUser(payload, this.user?.userId).toPromise().then((res) => {
      if(res){
        console.log({res});
        this.modalService.hideLoadingSpinner();
        this.modalService.hideUpdateUser();
        this.modalService.successModal(`User details has been successfully updated.`)
        this.store.dispatch(getUsers());
      }
    }).catch((err) => {
      this.modalService.hideLoadingSpinner();
      this.modalService.errorModal(err?.error?.message);
    })
  }

  cancel(){
    this.bsModalService.hide();
  }

  get name(){
    return this.form.get('name');
  }

  get email(){
    return this.form.get('email');
  }

  get password(){
    return this.form.get('password');
  }

  get status(){
    return this.form.get('status');
  }
}
