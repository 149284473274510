import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAccount from '../reducers/account.reducer';

export const selectState = createFeatureSelector<fromAccount.State>(
  fromAccount.featureKey
);

export const getUser = createSelector(selectState, (state) => state.user);
export const getRiders = createSelector(selectState, (state) => state.riders);
export const unAssignedDeliveries = createSelector(selectState, (state) => state.unAssignedDeliveries);
export const assignedDeliveries = createSelector(selectState, (state) => state.assignedDeliveries);
export const deliveryStatuses = createSelector(selectState, (state) => state.deliveryStatuses);
export const getDeliveries = createSelector(selectState, (state) => state.deliveries);
export const getRoles = createSelector(selectState, (state) => state.roles);
export const getPermissions = createSelector(selectState, (state) => state.permissions);
export const getUserList = createSelector(selectState, (state) => state.userList);
export const getCapturedImage = createSelector(selectState, (state) => state.capturedImage);
export const getSelectedFilter = createSelector(selectState, (state) => state.selectedFilter);

export const getDeliveriesCount = createSelector(selectState, (state) => state.deliveryCount);
export const getRidersCount = createSelector(selectState, (state) => state.ridersCount);
export const getRevenueCount = createSelector(selectState, (state) => state.revenueCount);
export const getPaymentCount = createSelector(selectState, (state) => state.paymentsCount);
export const getSmsStatus = createSelector(selectState, (state) => state.smsStatus);

export const getTerritories = createSelector(selectState, (state) => state.territories);
export const getRiderTerritories = createSelector(selectState, (state) => state.riderTerritories);
export const getUserTerritories = createSelector(selectState, (state) => state.userTerritories);
export const getTerritoryArea = createSelector(selectState, (state) => state.territoryArea);