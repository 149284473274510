import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { MaterialModule } from 'src/app/material/material.module';
import { ReactiveFormsModule } from '@angular/forms';

const routes: Routes = [
  {
    path: '',
    children: [
      { 
        path: '', 
        redirectTo: 'login' ,
        pathMatch: 'full'
      },
      { 
        path: 'login', 
        component: LoginComponent 
      },
    ],
  },
];

@NgModule({
  declarations: [
    LoginComponent
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    MaterialModule,
    ReactiveFormsModule,
  ]

})
export class AuthModule { }
