<div class="grid">
	<div class="row p-3">
		<div class="col-md-8">
			<h3>Upload Dayon Settlement</h3>
		</div>
		<div class="col-md-12">
			<div class="login-container">
				<form [formGroup]="form">
					<div class="row">
						<div class="col-md-12 d-flex justify-content-center p-0">
							<input type="file" accept=".csv, .xlsx" formControlName="file" (change)="getFileContent($event)">
						</div>
					</div>

					<div class="row">
						<div class="col-md-12 d-flex justify-content-center">
							<button class="btn btn-primary" mat-raised-button color="primary" (click)="submit()" [disabled]="!form.valid">Upload</button>
							<button class="btn btn-primary manual" mat-raised-button color="primary" (click)="toggleManualReconciliation()">Manual Reconcile</button>
						</div>
					</div>

					<div class="row mt-3">
						<div class="col-md-12 d-flex justify-content-center">
							<button mat-raised-button color="danger" (click)="cancel()" class="cancel">Cancel</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>