import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { selectStatusFilter } from 'src/app/store/actions/account.actions';
import { AppState } from 'src/app/store/app.state';

@Component({
  selector: 'app-select-filter',
  templateUrl: './select-filter.component.html',
  styleUrls: ['./select-filter.component.scss']
})
export class SelectFilterComponent implements OnInit {

  filter!: any;
  selectedFilter!: any;

  currentFilter: any;

  constructor(
    private bsModalService: BsModalService,
    private store: Store<AppState>
  ) { }

  ngOnInit() {
    this.currentFilter = this.selectedFilter;
    console.log(this.selectedFilter);
  }

  proceed(){
    this.store.dispatch(selectStatusFilter({data: this.currentFilter}));
    this.bsModalService.hide();
  }

  cancel(){
    this.bsModalService.hide();
  }

  onChange(item: any){
    this.selectedFilter = item;
    this.currentFilter = item;
    console.log(this.selectedFilter);
  }

}
