<div class="container">
	<div class="web-container">
    <div class="tabs-container">
      <div class="tab pointer" (click)="selectTab('item')" [ngClass]="{'tab-active': currentTab == 'item'}">
        <p class="t-fs14 m0">
          Item
        </p>
      </div>

      <div class="tab pointer" (click)="selectTab('recipient')" [ngClass]="{'tab-active': currentTab == 'recipient'}">
        <p class="t-fs14 m0">
          Recipient
        </p>
      </div>

      <div class="tab pointer" (click)="selectTab('rider')" [ngClass]="{'tab-active': currentTab == 'rider'}" *ngIf="delivery?.rider">
        <p class="t-fs14 m0">
          Rider
        </p>
      </div>

      <div class="tab pointer" (click)="selectTab('breakdown')" [ngClass]="{'tab-active': currentTab == 'breakdown'}">
        <p class="t-fs14 m0">
          Breakdown
        </p>
      </div>
    </div>

    <ng-container *ngIf="currentTab == 'item'">
      <div class="tab-container">
        <div class="row">
          <div class="col-3">
            <p class="t-fs12 t-fw700 m0">Reference ID</p>
          </div>
          <div class="col-9">
            <p class="t-fs16 t-fw700 m0">{{ delivery?.referenceId }}</p>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <p class="t-fs12 t-fw700 m0">Status</p>
          </div>
          <div class="col-9">
            <p class="t-fs16 t-fw700 m0 capitalize">{{ getStatus(delivery?.delivery_status?.deliveryStatus) }}</p>
          </div>
        </div>

        <div class="row" *ngFor="let item of delivery?.delivery_items">
          <div class="col-3">
            <p class="t-fs12 t-fw700 m0">Name</p>
          </div>
          <div class="col-9">
            <p class="t-fs16 t-fw700 m0">{{ item?.name }}</p>
          </div>

          <div class="col-3">
            <p class="t-fs12 t-fw700 m0">Amount</p>
          </div>
          <div class="col-9">
            <p class="t-fs16 t-fw700 m0">{{ item?.amount | number: '1.2-2' }}</p>
          </div>

          <div class="col-3">
            <p class="t-fs12 t-fw700 m0">Quantity</p>
          </div>
          <div class="col-9">
            <p class="t-fs16 t-fw700 m0">{{ item?.quantity }}</p>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="currentTab == 'recipient'">
      <div class="tab-container">
        <div class="row">
          <div class="row">
            <div class="col-4">
              <p class="t-fs12 t-fw700 m0">Recipient Name</p>
            </div>
            <div class="col-8">
              <p class="t-fs16 t-fw700 m0">{{ delivery?.recipient?.recipientName }}</p>
            </div>
          </div>
  
          <div class="row">
            <div class="col-4">
              <p class="t-fs12 t-fw700 m0">Address</p>
            </div>
            <div class="col-8">
              <p class="t-fs16 t-fw700 m0">{{ delivery?.address?.address1 }}</p>
            </div>
          </div>
  
          <div class="row">
            <div class="col-4">
              <p class="t-fs12 t-fw700 m0">Mobile Number</p>
            </div>
            <div class="col-8">
              <p class="t-fs16 t-fw700 m0">{{ delivery?.recipient?.phoneMobile }}</p>
            </div>
          </div>
          
          <div class="row">
            <div class="col-4">
              <p class="t-fs12 t-fw700 m0">Email Address</p>
            </div>
            <div class="col-8">
              <p class="t-fs16 t-fw700 m0">{{ delivery?.recipient?.emailAddress ? delivery?.recipient?.emailAddress : 'N/A' }}</p>
            </div>
          </div>

          <div class="row" *ngIf="delivery?.delivery_status?.signatureImage || delivery?.delivery_status?.statusImage">
            <div class="col-6">
              <img src="{{ delivery?.delivery_status?.signatureImage }}" alt="" class="signature">
            </div>
            <div class="col-6">
              <img src="{{ delivery?.delivery_status?.statusImage }}" alt="" class="signature">
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="currentTab == 'rider'">
      <div class="tab-container">
        <div class="row">
          <div class="col-3">
            <p class="t-fs12 t-fw700 m0">Rider Name</p>
          </div>
          <div class="col-9">
            <p class="t-fs16 t-fw700 m0 capitalize">{{ delivery?.rider?.riderName }}</p>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <p class="t-fs12 t-fw700 m0">Mobile Number</p>
          </div>
          <div class="col-9">
            <p class="t-fs16 t-fw700 m0">{{ delivery?.rider?.phoneMobile }}</p>
          </div>
        </div>
        
        <div class="row">
          <div class="col-3">
            <p class="t-fs12 t-fw700 m0">Plate Number</p>
          </div>
          <div class="col-9">
            <p class="t-fs16 t-fw700 m0">{{ delivery?.rider?.vehicleId }}</p>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="currentTab == 'breakdown'">
      <div class="tab-container">
        <div class="row">
          <div class="col-5">
            <p class="t-fs12 t-fw700 m0">Base Charge</p>
          </div>
          <div class="col-7">
            <p class="t-fs16 t-fw700 m0 capitalize">P{{ delivery?.base_charge }}</p>
          </div>
        </div>

        <div class="row">
          <div class="col-5">
            <p class="t-fs12 t-fw700 m0">Base Distance</p>
          </div>
          <div class="col-7">
            <p class="t-fs16 t-fw700 m0">P{{ delivery?.base_distance }}{{ delivery?.base_distance_unit }}</p>
          </div>
        </div>
        
        <div class="row">
          <div class="col-5">
            <p class="t-fs12 t-fw700 m0">Additional Distance</p>
          </div>
          <div class="col-7">
            <p class="t-fs16 t-fw700 m0">{{ delivery?.additional_distance }}{{ delivery?.additional_distance_unit }}</p>
          </div>
        </div>

        <div class="row">
          <div class="col-5">
            <p class="t-fs12 t-fw700 m0">Additional Distance Cost</p>
          </div>
          <div class="col-7">
            <p class="t-fs16 t-fw700 m0">P{{ delivery?.additional_distance_cost }}</p>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

	<div class="buttons mt-3">
		<div class="row">
			<div class="col-md-12 d-flex justify-content-center">
				<button class="btn btn-primary" (click)="close()">Close</button>
			</div>
		</div>
	</div>
</div>