<div class="d-lg-none offcanvas offcanvas-start" tabindex="-1" id="offcanvasAbyanMobile" aria-labelledby="offcanvasAbyanMobile">
    <div class="offcanvas-header">
      <a class="navbar-toggler" href="#" target="_blank">
        <svg-icon src="assets/custom/svg/menu-bar.svg" [svgStyle]="{ 'margin-right.px': 16 }" data-bs-toggle="offcanvas" data-bs-target="#offcanvasAbyanMobile" aria-controls="offcanvasAbyanMobile"></svg-icon>
        <img src="assets/img/abyan.png" alt="" class="abyan">
      </a>
    </div>
    <div class="offcanvas-body">
      <div class="abyan-menus">
        <ul class="list-group">
          <li class="abyan-menu-item list-group-item">
            <!-- <button type="button" class="btn btn-block btn-connected-wallet" (click)="logout()">Others</button> -->
            <div class="dropdown">
              <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                {{ userProfile?.name }}
              </button>
              <!-- <ul class="dropdown-menu">
                <li><a class="dropdown-item" (click)="logout()">Logout</a></li>
                <li><a class="dropdown-item">Another action</a></li>
                <li><a class="dropdown-item">Something else here</a></li>
              </ul> -->
            </div>
          </li>
          <li class="abyan-menu-item list-group-item">
            <a href="/home/dashboard" routerLink="/home/dashboard" routerLinkActive="active" class="nav-link">Dashboard</a>
          </li>
          <li class="abyan-menu-item list-group-item">
            <a href="/home/deliveries" routerLink="/home/deliveries" routerLinkActive="active" class="nav-link">Deliveries</a>
          </li>
          <li class="abyan-menu-item list-group-item">
            <a href="/home/riders" routerLink="/home/riders" routerLinkActive="active" class="nav-link">Riders</a>
          </li>
          <li class="abyan-menu-item list-group-item">
            <a href="/home/users" routerLink="/home/users" routerLinkActive="active" class="nav-link">Users</a>
          </li>
          <li class="abyan-menu-item list-group-item">
            <a href="/home/roles" routerLink="/home/roles" routerLinkActive="active" class="nav-link">Roles</a>
          </li>
          <li class="abyan-menu-item list-group-item pointer">
            <a routerLinkActive="active" class="nav-link" (click)="logout()">Logout</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="offcanvas-footer ">
      <div class="footer d-flex justify-content-center">
        <div class="d-flex flex-column align-items-center justify-content-center">
          <span class="copyright">© 2022 <a href="#" target="_blank">ABYAN</a></span>
          <span class="poweredby">Copyright © ABYAN Protocol. All Rights Reserved.</span>
        </div>
      </div>
    </div>
  </div>