import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { NgxScannerQrcodeComponent, ScannerQRCodeConfig, ScannerQRCodeResult, ScannerQRCodeSelectedFiles } from 'ngx-scanner-qrcode';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountService } from 'src/app/services/account.service';
import { ModalService } from 'src/app/services/modal.service';
import { setLoadingSpinner } from 'src/app/store/actions/shared.actions';
import { AppState } from 'src/app/store/app.state';
import { getCapturedImage } from 'src/app/store/selectors/account.selectors';

@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss']
})
export class ScannerComponent implements OnInit, AfterViewInit {

  @ViewChild('action') action!: NgxScannerQrcodeComponent;

  public config: ScannerQRCodeConfig = {
    isBeep: false,
    vibrate: 0,
    constraints: {
      video: {
        width: window.innerWidth,
      },
    },
  };

  scannedData: any = []

  getCapturedImage$: Observable<any>;
  capturedImage: any;
  ngUnsubscribe = new Subject();

  public qrCodeResult: ScannerQRCodeSelectedFiles[] = [];
  public qrCodeResult2: ScannerQRCodeSelectedFiles[] = [];

  blobImg: any;

  testScanned: any = [];

  constructor(
    private accountService: AccountService,
    private store: Store<AppState>,
    private modalService: ModalService
  ) {
    this.getCapturedImage$ = this.store.select(getCapturedImage)
   }

  ngOnInit(): void {
    this.getCapturedImage$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      if(res){
        console.log(res);
        this.blobImg = res?.blobImage;
        this.capturedImage = res?.base64String
      }
    });

    // var i;
    
    // let possible = "1234567890";
    // const lengthOfCode = 40;
    // for (i = 0; i < 2000; i++){
    //   this.testScanned.push({refCode: this.makeRandom(11, possible)})
    // }

    // console.log(this.testScanned);
  }

  ngAfterViewInit(): void {
    this.action.isReady.subscribe((res: any) => {
      // this.handle(this.action, 'start');
    });
  }

  makeRandom(lengthOfCode: number, possible: string) {
    let text = "";
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
      return text;
  }

  onError(e: any){
    alert(e);
  }

  public async onEvent(e: ScannerQRCodeResult|any, action?: any): Promise<void> {
    // e && action && action.pause();
    var value = e[0].value;
    console.log({value});
    if(value){
      action.pause();
      if(this.scannedData?.length > 0){
        var checkRefCode = this.scannedData?.filter((e: any) => e?.refCode == value)[0];
        if(!checkRefCode){
          this.scannedData.push({refCode: value});
          console.log(this.scannedData);
      
          setTimeout(() => {
            action.play();
          }, 2000);
        }
        else{
          const message = 'You have already scanned this qr code. Please scan another';
          var errorModal = await this.modalService.errorModal(message);
          errorModal.onHidden?.subscribe(() => {
            action.play();
          })
        }
      }
      else{
        this.scannedData.push({refCode: value});
        console.log(this.scannedData);

        setTimeout(() => {
          action.play();
        }, 2000);
      }

    }
  }

  public handle(action: any, fn: string): void {
    const playDeviceFacingBack = (devices: any[]) => {
      // front camera or back camera check here!
      const device = devices.find((f) =>
        /back|rear|environment/gi.test(f.label)
      ); // Default Back Facing Camera
      action.playDevice(device ? device.deviceId : devices[0].deviceId);
    };

    if (fn === 'start') {
      action[fn](playDeviceFacingBack).subscribe(
        (r: any) => console.log(fn, r),
        alert
      );
    } else {
      action[fn]().subscribe((r: any) => console.log(fn, r), alert);
    }
  }

  checkScannedData(){
    console.log(this.scannedData)
  }

  receiveParcels(){
    let deliveries = this.scannedData.map((item: any) => {
      return {transactionReferenceNumber: item.refCode};
    });
    if(deliveries.length > 0){
      const payload = {
        deliveries,
        deliveryStatus: "09 received"
      }

      const formData = new FormData();

      for (const [index, value] of this.scannedData.entries()){
        console.log(index, value);
        formData.append(`deliveries[${index}][transactionReferenceNumber]`, value?.refCode);
      }

      formData.append('deliveryStatus', '09 received');
      formData.append('proofOfdelivery', this.blobImg);

      // console.log({payload});
      this.modalService.showLoadingSpinner();
      this.accountService.updateDeliveryStatus(formData).toPromise().then((res) => {
        if(res){
          console.log({res});
          this.modalService.hideLoadingSpinner();

          this.modalService.hideScannerModal();
          this.modalService.successModal(`You have successfully updated delivery status`);
        }
      }).catch((err) => {
        console.log({err});
        this.modalService.hideLoadingSpinner();
        this.modalService.errorModal(err?.error?.message);
      })
    }
  }

  openCamera(){
    var modal = this.modalService.openCameraModal();

    // modal.onHidden.subscribe((data) => {
    //   console.log(data);
    // })
  }

  dismiss(){
    this.modalService.hideScannerModal();
  }

}
