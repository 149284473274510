import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AccountService } from 'src/app/services/account.service';
import { ModalService } from 'src/app/services/modal.service';
import * as fromAccountActions from '../../../store/actions/account.actions'
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.state';

@Component({
  selector: 'app-remove-role-confirm',
  templateUrl: './remove-role-confirm.component.html',
  styleUrls: ['./remove-role-confirm.component.scss']
})
export class RemoveRoleConfirmComponent implements OnInit {

  role!: any;

  constructor(
    private bsModalService: BsModalService,
    private modalService: ModalService,
    private accountService: AccountService,
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {

  }

  confirm(){
    const payload = {
      role: this.role?.name
    }
    console.log({payload});
    this.modalService.showLoadingSpinner();
    this.accountService.removeRole(payload).then((res) => {
      if(res){
        console.log({res})
        this.modalService.hideLoadingSpinner();
        this.store.dispatch(fromAccountActions.getRoles());
        this.modalService.hideRemoveRole();
        this.modalService.successModal(`${this.role.name} has been successfully removed.`);
      }
    });
  }

  dismiss(){
    this.modalService.hideRemoveRole();
  }
}
