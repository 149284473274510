import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { ROUTER_NAVIGATION, RouterNavigatedAction } from '@ngrx/router-store';
import {  exhaustMap, filter, map, mergeMap } from 'rxjs/operators';
import { APIResponse } from 'src/app/models/api-response.model';
import { SharedService } from 'src/app/services/shared.service';
import { failedTransaction } from '../actions/shared.actions';
import { tap } from 'rxjs/operators';

import * as fromSharedActions from 'src/app/store/actions/shared.actions'
import { OfflineStorageService } from 'src/app/services/offline-storage.service';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { ModalService } from 'src/app/services/modal.service';


@Injectable()
export class SharedEffects {
  failedTransaction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(failedTransaction),
        tap(async (action) => {
          console.log('failedTransaction$', action.data);          
          this.modalService.errorModal(action.data);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private sharedService: SharedService,
    private offlineStorageService: OfflineStorageService,
    private store: Store<AppState>,
    private modalService: ModalService
  ) {}
}
