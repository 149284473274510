import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AlertService {

  constructor() { }

  async showInfoMessage(msg = '') {
    // const alert = await this.alertController.create({
    //   header: 'Info',
    //   message: msg,
    //   backdropDismiss: false,
    //   buttons: ['OK']
    // });
    // await alert.present();
  }

  async showErrorMessage(msg = '') {
    // const alert = await this.alertController.create({
    //   header: 'Error',
    //   message: msg,
    //   backdropDismiss: false,
    //   buttons: ['OK']
    // });
    // await alert.present();
  }
}
