import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SuccessComponent } from '../shared/modals/success/success.component';
import { ErrorComponent } from '../shared/modals/error/error.component';
import { AssignRoleComponent } from '../shared/modals/assign-role/assign-role.component';
import { AssignPermissionComponent } from '../shared/modals/assign-permission/assign-permission.component';
import { CreateRoleComponent } from '../shared/modals/create-role/create-role.component';
import { RemoveRoleConfirmComponent } from '../shared/modals/remove-role-confirm/remove-role-confirm.component';
import { LoadingSpinnerComponent } from '../shared/modals/loading-spinner/loading-spinner.component';
import { AssignDeliveriesComponent } from '../shared/modals/assign-deliveries/assign-deliveries.component';
import { UpdateUserComponent } from '../shared/modals/update-user/update-user.component';
import { CreateUserComponent } from '../shared/modals/create-user/create-user.component';
import { UpdateRiderStatusComponent } from '../shared/modals/update-rider-status/update-rider-status.component';
import { ScannerComponent } from '../shared/modals/scanner/scanner.component';
import { CaptureImageComponent } from '../shared/modals/capture-image/capture-image.component';
import { SelectFilterComponent } from '../shared/modals/select-filter/select-filter.component';
import { DeliveryListComponent } from '../shared/modals/delivery-list/delivery-list.component';
import { ViewDeliveryDetailsComponent } from '../shared/modals/view-delivery-details/view-delivery-details.component';
import { UpdateSmsStatusComponent } from '../shared/modals/update-sms-status/update-sms-status.component';
import { CreateSmsStatusComponent } from '../shared/modals/create-sms-status/create-sms-status.component';
import { RemoveSmsStatusConfirmComponent } from '../shared/modals/remove-sms-status-confirm/remove-sms-status-confirm.component';
import { UploadDayonFileComponent } from '../shared/modals/upload-dayon-file/upload-dayon-file.component';
import { CreateTerritoryComponent } from '../shared/modals/create-territory/create-territory.component';
import { UpdateTerritoryComponent } from '../shared/modals/update-territory/update-territory.component';
import { CreateRiderTerritoryComponent } from '../shared/modals/create-rider-territory/create-rider-territory.component';
import { UpdateRiderTerritoryComponent } from '../shared/modals/update-rider-territory/update-rider-territory.component';
import { CreateUserTerritoryComponent } from '../shared/modals/create-user-territory/create-user-territory.component';
import { UpdateUserTerritoryComponent } from '../shared/modals/update-user-territory/update-user-territory.component';
import { CreateTerritoryAreaComponent } from '../shared/modals/create-territory-area/create-territory-area.component';
import { UpdateTerritoryAreaComponent } from '../shared/modals/update-territory-area/update-territory-area.component';
import { ReAssignDeliveriesComponent } from '../shared/modals/re-assign-deliveries/re-assign-deliveries.component';
// import { AddVestComponent } from '../shared/modals/add-vest/add-vest.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
    
    modalRefSucess!: BsModalRef;
    modalRefError!: BsModalRef;
    modalRefAssignRole!: BsModalRef;
		modalRefAssignPermission!: BsModalRef;
		modalRefRemovePermission!: BsModalRef;
		modalRefCreateRole!: BsModalRef;
		modalRefRemoveRole!: BsModalRef;
		modalRefLoadingSpinner!: BsModalRef;
		modalRefAssignDeliveries!: BsModalRef;
    modalRefReAssignDeliveries!: BsModalRef;
		modalRefUpdateUser!: BsModalRef;
		modalRefCreateUser!: BsModalRef;
    modalRefUpdateRiderStatus!: BsModalRef;
    modalRefScanner!: BsModalRef;
    modalRefCamera!: BsModalRef;
    modalRefSelectFilter!: BsModalRef;
    modalRefViewDeliveries!: BsModalRef;
    modalRefViewDeliveryDetails!: BsModalRef;
    modalRefCreateSmsStatus!: BsModalRef;
    modalRefUpdateSmsStatus!: BsModalRef;
    modalRefDeleteSmsStatus!: BsModalRef;
    modalRefUploadDayonFile!: BsModalRef;
    modalRefCreateTerritory!: BsModalRef;
    modalRefUpdateTerritory!: BsModalRef;

    modalRefCreateRiderTerritory!: BsModalRef;
    modalRefUpdateRiderTerritory!: BsModalRef;


    modalRefCreateUserTerritory!: BsModalRef;
    modalRefUpdateUserTerritory!: BsModalRef;

    modalRefCreateTerritoryArea!: BsModalRef;
    modalRefUpdateTerritoryArea!: BsModalRef;

    constructor(
        private modalService: BsModalService
    ) {}

    
  successModal(message: any){
    const initialState = {
      message,
    };
    const config: any = {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered success-modal'
    }

    return this.modalRefSucess = this.modalService.show(SuccessComponent, config);
  }

	hideSuccessModal(){
    if(this.modalRefSucess){
			this.modalRefSucess.hide();
    }
	}

  errorModal(message: any){
    const initialState = {
      message,
    };
    const config: any = {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal modal-dialog-centered success-modal'
    }

    return this.modalRefError = this.modalService.show(ErrorComponent, config);
  }

	hideErrorModal(){
    if(this.modalRefError){
			this.modalRefError.hide();
    }
	}

  showAssignRole(initialState: any){
		const config: any = {
			initialState,
			backdrop: true,
			ignoreBackdropClick: true,
			class: 'modal-dialog-centered select-token-modal'
		}

		return this.modalRefAssignRole = this.modalService.show(AssignRoleComponent, config);
  }

  hideAssignRole(){
    if(this.modalRefAssignRole){
			this.modalRefAssignRole.hide();
    }
	}

	assignPermission(initialState: any){
    const config: any = {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered select-token-modal'
    }

    return this.modalRefAssignPermission = this.modalService.show(AssignPermissionComponent, config);
  }

	hideAssignPermission(){
    if(this.modalRefAssignPermission){
			this.modalRefAssignPermission.hide();
    }
	}

  removePermissions(initialState: any){
    const config: any = {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered select-token-modal'
    }

    return this.modalRefRemovePermission = this.modalService.show(AssignPermissionComponent, config);
  }

	hideRemovePerimission(){
    if(this.modalRefRemovePermission){
			this.modalRefRemovePermission.hide();
    }
	}

	createRole(){
		const config: any = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered select-token-modal'
    }

    return this.modalRefCreateRole = this.modalService.show(CreateRoleComponent, config);
	}

	hideCreateRole(){
    if(this.modalRefCreateRole){
			this.modalRefCreateRole.hide();
    }
	}

	showRemoveRole(initialState: any){
		const config: any = {
			initialState,
			backdrop: true,
			ignoreBackdropClick: true,
			class: 'modal-dialog-centered success-modal'
		}

		return this.modalRefRemoveRole = this.modalService.show(RemoveRoleConfirmComponent, config);
  }

  hideRemoveRole(){
    if(this.modalRefRemoveRole){
			this.modalRefRemoveRole.hide();
    }
	}

	showLoadingSpinner(){
		const config: any = {
			backdrop: true,
			ignoreBackdropClick: true,
			keyboard: false,
			class: 'modal-dialog-centered loading-spinner'
		}

		return this.modalRefLoadingSpinner = this.modalService.show(LoadingSpinnerComponent, config);
  }

  hideLoadingSpinner(){
    if(this.modalRefLoadingSpinner){
			this.modalRefLoadingSpinner.hide();
    }
	}

	assignDeliveries(initialState: any){
    const config: any = {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered assign-deliveries-modal'
    }

    return this.modalRefAssignDeliveries = this.modalService.show(AssignDeliveriesComponent, config);
  }

	hideAssignDeliveries(){
    if(this.modalRefAssignDeliveries){
			this.modalRefAssignDeliveries.hide();
    }
	}

	updateUser(initialState: any){
    const config: any = {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered select-token-modal'
    }

    return this.modalRefUpdateUser = this.modalService.show(UpdateUserComponent, config);
  }

	hideUpdateUser(){
    if(this.modalRefUpdateUser){
			this.modalRefUpdateUser.hide();
    }
	}

	createUser(){
    const config: any = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered select-token-modal'
    }

    return this.modalRefCreateUser = this.modalService.show(CreateUserComponent, config);
  }

	hideCreateUser(){
    if(this.modalRefCreateUser){
			this.modalRefCreateUser.hide();
    }
	}

  updateRiderStatus(initialState: any){
    const config: any = {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered assign-deliveries-modal',
    }

    return this.modalRefUpdateRiderStatus = this.modalService.show(UpdateRiderStatusComponent, config);
  }

	hideUpdateRiderStatus(){
    if(this.modalRefUpdateRiderStatus){
			this.modalRefUpdateRiderStatus.hide();
    }
	}

  openScannerModal(){
    const config: any = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered scanner-modal',
    }

    return this.modalRefScanner = this.modalService.show(ScannerComponent, config);
  }

	hideScannerModal(){
    if(this.modalRefScanner){
			this.modalRefScanner.hide();
    }
	}

  openCameraModal(){
    const config: any = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered capture-image-modal'
    }

    return this.modalRefCamera = this.modalService.show(CaptureImageComponent, config);
  }

	hideCameraModal(){
    if(this.modalRefScanner){
			this.modalRefCamera.hide();
    }
	}

  selectFilter(initialState: any){
    const config: any = {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered assign-deliveries-modal'
    }

    return this.modalRefSelectFilter = this.modalService.show(SelectFilterComponent, config);
  }

	hideSelectFilter(){
    if(this.modalRefSelectFilter){
			this.modalRefSelectFilter.hide();
    }
	}

  openDeliveryList(initialState: any){
    const config: any = {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered assign-deliveries-modal'
    }

    return this.modalRefViewDeliveries = this.modalService.show(DeliveryListComponent, config);
  }

	hideDeliveryList(){
    if(this.modalRefViewDeliveries){
			this.modalRefViewDeliveries.hide();
    }
	}

  openViewDeliveryDetails(initialState: any){
    const config: any = {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered view-delivery-modal'
    }

    return this.modalRefViewDeliveryDetails = this.modalService.show(ViewDeliveryDetailsComponent, config);
  }

	hideViewDeliveryDetails(){
    if(this.modalRefViewDeliveryDetails){
			this.modalRefViewDeliveryDetails.hide();
    }
	}

  openCreateSmsStatus(initialState){
    const config: any = {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered select-token-modal'
    }

    return this.modalRefCreateSmsStatus = this.modalService.show(CreateSmsStatusComponent, config);
  }

	hideCreateSmsStatus(){
    if(this.modalRefCreateSmsStatus){
			this.modalRefCreateSmsStatus.hide();
    }
	}

  openUpdateSmsStatus(initialState: any){
    const config: any = {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered select-token-modal'
    }

    return this.modalRefUpdateSmsStatus = this.modalService.show(UpdateSmsStatusComponent, config);
  }

	hideUpdateSmsStatus(){
    if(this.modalRefUpdateSmsStatus){
			this.modalRefUpdateSmsStatus.hide();
    }
	}

  openDeleteSmsStatus(initialState: any){
    const config: any = {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered delete-sms-status-modal'
    }

    return this.modalRefDeleteSmsStatus = this.modalService.show(RemoveSmsStatusConfirmComponent, config);
  }

	hideDeleteSmsStatus(){
    if(this.modalRefDeleteSmsStatus){
			this.modalRefDeleteSmsStatus.hide();
    }
	}

  openUploadDayonFile(){
    const config: any = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered select-token-modal'
    }

    return this.modalRefUploadDayonFile = this.modalService.show(UploadDayonFileComponent, config);
  }

	hideUploadDayonFile(){
    if(this.modalRefUploadDayonFile){
			this.modalRefUploadDayonFile.hide();
    }
	}

  openCreateTerritory(){
    const config: any = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered select-token-modal'
    }

    return this.modalRefCreateTerritory = this.modalService.show(CreateTerritoryComponent, config);
  }

	hideCreateTerritory(){
    if(this.modalRefCreateTerritory){
			this.modalRefCreateTerritory.hide();
    }
	}

  openUpdateTerritory(initialState){
    const config: any = {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered select-token-modal'
    }

    return this.modalRefUpdateTerritory = this.modalService.show(UpdateTerritoryComponent, config);
  }

	hideUpdateTerritory(){
    if(this.modalRefUpdateTerritory){
			this.modalRefUpdateTerritory.hide();
    }
	}

  openCreateRiderTerritory(initialState){
    const config: any = {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered select-token-modal'
    }

    return this.modalRefCreateRiderTerritory = this.modalService.show(CreateRiderTerritoryComponent, config);
  }

	hideCreateRiderTerritory(){
    if(this.modalRefCreateRiderTerritory){
			this.modalRefCreateRiderTerritory.hide();
    }
	}

  openUpdateRiderTerritory(initialState){
    const config: any = {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered select-token-modal'
    }

    return this.modalRefUpdateRiderTerritory = this.modalService.show(UpdateRiderTerritoryComponent, config);
  }

	hideUpdateRiderTerritory(){
    if(this.modalRefUpdateRiderTerritory){
			this.modalRefUpdateRiderTerritory.hide();
    }
	}

  openCreateUserTerritory(initialState){
    const config: any = {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered select-token-modal'
    }

    return this.modalRefCreateUserTerritory = this.modalService.show(CreateUserTerritoryComponent, config);
  }

	hideCreateUserTerritory(){
    if(this.modalRefCreateUserTerritory){
			this.modalRefCreateUserTerritory.hide();
    }
	}

  openUpdateUserTerritory(initialState){
    const config: any = {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered select-token-modal'
    }

    return this.modalRefUpdateUserTerritory = this.modalService.show(UpdateUserTerritoryComponent, config);
  }

	hideUpdateUserTerritory(){
    if(this.modalRefUpdateUserTerritory){
			this.modalRefUpdateUserTerritory.hide();
    }
	}

  openCreateTerritoryArea(initialState){
    const config: any = {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered select-token-modal'
    }

    return this.modalRefCreateTerritoryArea = this.modalService.show(CreateTerritoryAreaComponent, config);
  }

  hideCreateTerritoryArea(){
    if(this.modalRefCreateTerritoryArea){
      this.modalRefCreateTerritoryArea.hide();
    }
  }

  openUpdateTerritoryArea(initialState){
    const config: any = {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered select-token-modal'
    }

    return this.modalRefUpdateTerritoryArea = this.modalService.show(UpdateTerritoryAreaComponent, config);
  }

  hideUpdateTerritoryArea(){
    if(this.modalRefUpdateTerritoryArea){
      this.modalRefUpdateTerritoryArea.hide();
    }
  }

  openReAssignDeliveries(initialState: any){
    const config: any = {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered re-assign-deliveries-modal'
    }

    return this.modalRefReAssignDeliveries = this.modalService.show(ReAssignDeliveriesComponent, config);
  }

	hideReAssignDeliveries(){
    if(this.modalRefReAssignDeliveries){
			this.modalRefReAssignDeliveries.hide();
    }
	}
}
