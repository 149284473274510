import { createReducer, on } from '@ngrx/store';
import * as fromAddress from '../actions/address.actions';
import { Provinces } from 'src/app/models/refProvinces.model';
import { CityMun } from 'src/app/models/refCities.model';
import { Barangays } from 'src/app/models/refBarangays.model';

export const featureKey = 'address';

export interface State {
    countries: {
        data: any,
        isLoaded: Boolean
    };
    provinces: {
        data: any,
        isLoaded: Boolean
    };
    cities: {
        data: any,
        isLoaded: Boolean
    };
    barangays: {
        data: any,
        isLoaded: Boolean
    };
}

export const initialState: State = {
    countries: {
        data: null,
        isLoaded: false
    },
    provinces: {
        data: null,
        isLoaded: false
    },
    cities: {
        data: null,
        isLoaded: false
    },
    barangays: {
        data: null,
        isLoaded: false
    },
}

export const reducer = createReducer(
    initialState,
    on(fromAddress.getCountryListSuccess, (state: State, props: any) => ({
        ...state,
        countries: {data: props.data, isLoaded: true}
    })),
    on(fromAddress.getProvincesSuccess, (state: State, props: any) => ({
        ...state,
        provinces: {data: props.data, isLoaded: true}
    })),
    on(fromAddress.getCitiesSuccess, (state: State, props: any) => ({
        ...state,
        cities: {data: props.data, isLoaded: true}
    })),
    on(fromAddress.getBarangaysSuccess, (state: State, props: any) => ({
        ...state,
        barangays: {data: props.data, isLoaded: true}
    })),
);