<div class="container">
    <div class="grid">
        <div class="row">
            <div class="col-md-12 text-center">
                <img src="assets/img/error.png" alt="">
                <h2 class="m-0 mt-3">Something went wrong</h2>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 text-center">
                <h4 class="m-0 mt-2">{{ message }}</h4>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 d-flex justify-content-center">
                <button mat-raised-button color="primary" class="btn-add mt-3 btn btn-primary" (click)="dismiss()">
                    Dismiss
                </button>
            </div>
        </div>
    </div>
</div>