<div class="scanner">
  <div class="row">
    <div class="col-md-6 left">
      <div class="row">
        <div class="col-md-12">
          <h1 class="t-fs22 t-fw700 m-0">1. Let's scan the QR code</h1>
          <h3 class="t-fs16 t-lh22-4 t-fw400 m-0">Make sure the QR code is visible inside the frame.</h3>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="scanner-container">
            <ngx-scanner-qrcode #action="scanner" [config]="config" (event)="onEvent($event, action)"></ngx-scanner-qrcode>
          </div>

          <div class="row">
            <div class="col-md-12">
              <p *ngIf="action.isLoading">⌛ Loading...</p>
            </div>
          </div>
            
          <div class="row">
            <div class="col-md-12">
              <button class="btn" [class.btn-info]="!action.isStart" [class.btn-warning]="action.isStart" [disabled]="action.isLoading" (click)="handle(action, action.isStart ? 'stop' : 'start')">
                {{ !action.isStart ? 'Open Scanner' : 'Close Scanner' }}
              </button>
            </div>
          </div>
        
        </div>
      </div>
      
      <div class="row mt-2" *ngIf="capturedImage">
        <div class="col-md-12 d-flex justify-content-center align-items-center">
          <img src="{{ capturedImage }}" alt="" class="captured-img">
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-12">
          <h1 class="t-fs22 t-fw700 m-0">2. Take a photo</h1>
          <h3 class="t-fs16 t-lh22-4 t-fw400 m-0">Then click “Take Photo” to capture proof of item.</h3>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-12">
          <button class="btn btn-info" (click)="openCamera()">
            Take Photo
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-6 right">
      <div class="right-main-container">
        <div class="row">
          <div class="col-md-12">
            <h1 class="t-fs22 t-fw700 m-0">List of scanned items</h1>
          </div>
        </div>
  
        <div class="scanned-list" *ngIf="scannedData?.length == 0">
          <div class="items d-flex align-items-center">
            <h5 class="t-fs14 t-lh20 t-fw400 m-0 mr-10">Scanned Items will appear here.</h5>
          </div>
        </div>
  
        <div class="scanned-list">
          <div class="items d-flex align-items-center" *ngFor="let item of scannedData; let i=index">
            <h5 class="t-fs14 t-lh20 t-fw700 m-0 mr-10">{{ i+1 }}.</h5>
            <h5 class="t-fs14 t-lh20 t-fw400 m-0 mr-10">{{ item?.refCode }}</h5>
          </div>
        </div>
      </div>

      <div class="submit">
        <div class="row">
          <div class="col-md-6">
            <button class="btn btn-info" (click)="receiveParcels()" [disabled]="!capturedImage || scannedData?.length == 0">Submit</button>
          </div>
          <div class="col-md-6">
            <button class="btn btn-light" (click)="dismiss()">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="row">
    <div class="col-md-12">
      <p *ngIf="action.isLoading">⌛ Loading...</p>
    </div>
  </div>
    
  <div class="row">
    <div class="col-md-12">
      <button class="btn" [class.btn-info]="!action.isStart" [class.btn-warning]="action.isStart" [disabled]="action.isLoading" (click)="handle(action, action.isStart ? 'stop' : 'start')">
        <img [src]="action.isStart ? 'https://id1945.github.io/images/svg/camera-off.svg' : 'https://id1945.github.io/images/svg/camera-on.svg'" width="30px"/>
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="row" *ngIf="scannedData?.length == 0">
        <h2>No data found</h2>
      </div>
      <div class="row" *ngFor="let item of scannedData">
        <div class="col-md-12">
          <h5>{{ item?.refCode }}</h5>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6 d-flex justify-content-center">
			<button class="btn btn-primary" (click)="receiveParcels()" [disabled]="scannedData?.length == 0">
				Process
			</button>
		</div>
    <div class="col-md-6 d-flex justify-content-center">
			<button class="btn btn-primary" (click)="openCamera()">
				Take Photo
			</button>
		</div>
  </div> -->
</div>