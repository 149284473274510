<div class="grid">
	<div class="row p-3">
		<div class="col-md-12">
			<h3>Update User Territory</h3>
		</div>
		<div class="col-md-12">
			<div class="login-container">
				<form [formGroup]="form">
					<div class="row">
						<div class="col-md-12 d-flex justify-content-center p-0">
							<mat-form-field appearance="outline">
								<mat-label>User</mat-label>
								<select matNativeControl formControlName="userId">
									<option value="{{ item?.userId }}" *ngFor="let item of users">{{ item?.name }}</option>
								</select>
							</mat-form-field>
						</div>
					</div>

					<div class="row">
						<div class="col-md-12 d-flex justify-content-center p-0">
							<mat-form-field appearance="outline">
								<mat-label>Area Status</mat-label>
								<select matNativeControl formControlName="areaStatus">
									<option value="active">Active</option>
									<option value="inactive">Inactive</option>
								</select>
							</mat-form-field>
						</div>
					</div>
	
					<div class="row">
						<div class="col-md-12 d-flex justify-content-center">
							<button class="btn btn-primary" mat-raised-button color="primary" (click)="updateUserTerritory()" [disabled]="!form.valid">Submit</button>
							<button mat-raised-button color="danger" (click)="cancel()" class="cancel">Cancel</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>