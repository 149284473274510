import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AccountService } from 'src/app/services/account.service';
import { ModalService } from 'src/app/services/modal.service';
import { createRiderTerritories, createTerritories, createUserTerritories, getRoles, getSmsStatus, getUsers } from 'src/app/store/actions/account.actions';
import { logIn } from 'src/app/store/actions/auth.actions';
import { setLoadingSpinner } from 'src/app/store/actions/shared.actions';
import { AppState } from 'src/app/store/app.state';
import { MustMatch } from 'src/app/validators/mustmatch.validator';

@Component({
  selector: 'app-create-user-territory',
  templateUrl: './create-user-territory.component.html',
  styleUrls: ['./create-user-territory.component.scss']
})
export class CreateUserTerritoryComponent implements OnInit {

  users!: any;
  territory!: any;

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      userId: ['', [Validators.required]],
      areaStatus: ['', [Validators.required]]
    });
  }

  createRiderTerritory(){
    const payload = {
      userId: this.userId?.value,
      areaStatus: this.areaStatus?.value
    }

    console.log({payload})

    this.modalService.showLoadingSpinner();
    this.store.dispatch(createUserTerritories({data: payload, territory: this.territory}));
  }

  cancel(){
    this.modalService.hideCreateUserTerritory();
  }

  get userId(){
    return this.form.get('userId');
  }

  get areaStatus(){
    return this.form.get('areaStatus');
  }
}
