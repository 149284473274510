import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-view-delivery-details',
  templateUrl: './view-delivery-details.component.html',
  styleUrls: ['./view-delivery-details.component.scss']
})
export class ViewDeliveryDetailsComponent implements OnInit {

  delivery!: any;

  currentTab: any = 'item';

  constructor(
    private modalService: ModalService
  ) { }

  ngOnInit() {
    console.log(this.delivery)
  }

  close(){
    this.modalService.hideViewDeliveryDetails();
  }

  getStatus(str: any){
    var withNoDigits = str.replace(/[0-9]/g, '');
    return withNoDigits;
  }

  selectTab(tab){
    this.currentTab = tab;
  }

}
