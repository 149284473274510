/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';

export enum ActionTypes {
    GET_COUNTRY = '[Address] Get Country List',
    GET_COUNTRY_SUCCESS = '[Address] Get Country List Success',
    GET_PROVINCES = '[Address] Get Province List',
    GET_PROVINCES_SUCCESS = '[Address] Get Province List Success',
    GET_PROVINCES_FAILED = '[Address] Get Province List Failed',
    GET_CITIES = '[Address] Get City List',
    GET_CITIES_SUCCESS = '[Address] Get City List Success',
    GET_CITIES_FAILED = '[Address] Get City List Failed',
    GET_BARANGAYS = '[Address] Get Barangay List',
    GET_BARANGAYS_SUCCESS = '[Address] Get Barangay List Success',
    GET_BARANGAYS_FAILED = '[Address] Get Barangay List Failed',
    LOADING_PROVINCES = '[Loading] Loading Province List',
    LOADING_CITIES = '[Loading] Loading City List',
    LOADING_BARANGAYS = '[Loading] Loading Barangay List',
}

export const getProvinces = createAction(
    ActionTypes.GET_PROVINCES,
    props<{ data: any }>()
);

export const getProvincesSuccess = createAction(
    ActionTypes.GET_PROVINCES_SUCCESS,
    props<{ data: any }>()
);

export const getProvincesFailed = createAction(
    ActionTypes.GET_PROVINCES_FAILED,
    props<{ data: any }>()
);

export const getCities = createAction(
    ActionTypes.GET_CITIES,
    props<{ data: any }>()
);

export const getCitiesSuccess = createAction(
    ActionTypes.GET_CITIES_SUCCESS,
    props<{ data: any }>()
);

export const getCitiesFailed = createAction(
    ActionTypes.GET_CITIES_FAILED,
    props<{ data: any }>()
);

export const getBarangays = createAction(
    ActionTypes.GET_BARANGAYS,
    props<{ data: any }>()
);

export const getBarangaysSuccess = createAction(
    ActionTypes.GET_BARANGAYS_SUCCESS,
    props<{ data: any }>()
);

export const getBarangaysFailed = createAction(
    ActionTypes.GET_BARANGAYS_FAILED,
    props<{ data: any }>()
);

export const setProvinceLoadingStatus = createAction(
    ActionTypes.LOADING_PROVINCES,
    props<{ data: any }>()
);

export const setCityLoadingStatus = createAction(
    ActionTypes.LOADING_CITIES,
    props<{ data: any }>()
);

export const setBarangayLoadingStatus = createAction(
    ActionTypes.LOADING_BARANGAYS,
    props<{ data: any }>()
);

export const getCountryList = createAction(
    ActionTypes.GET_COUNTRY
);

export const getCountryListSuccess = createAction(
    ActionTypes.GET_COUNTRY_SUCCESS,
    props<{ data: any }>()
);
