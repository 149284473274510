import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AccountService } from 'src/app/services/account.service';
import { ModalService } from 'src/app/services/modal.service';
import { getRoles, getSmsStatus, getUsers, manualReconciliation } from 'src/app/store/actions/account.actions';
import { logIn } from 'src/app/store/actions/auth.actions';
import { setLoadingSpinner } from 'src/app/store/actions/shared.actions';
import { AppState } from 'src/app/store/app.state';
import { MustMatch } from 'src/app/validators/mustmatch.validator';

@Component({
  selector: 'app-upload-dayon-file',
  templateUrl: './upload-dayon-file.component.html',
  styleUrls: ['./upload-dayon-file.component.scss']
})
export class UploadDayonFileComponent implements OnInit {
  form: FormGroup;

  dayonFile: any;

  formData = new FormData();

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private accountService: AccountService,
    private bsModalService: BsModalService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      file: ['', [Validators.required]]
    });
  }

  cancel(){
    this.modalService.hideUploadDayonFile();
  }

  getFileContent(file){
    this.dayonFile = file?.target?.files[0];
    this.formData.append('file', this.dayonFile);
  }

  submit(){
    this.modalService.showLoadingSpinner();
    this.accountService.uploadDayonFile(this.formData).toPromise().then((res) => {
      if(res){
        console.log({res});
        this.modalService.hideLoadingSpinner();
        this.modalService.successModal(`Your file has been successfully uploaded.`);
        this.modalService.hideUploadDayonFile();
      }
    }).catch((err) => {
      console.log({err})
    })
  }

  toggleManualReconciliation(){
    this.modalService.showLoadingSpinner();
    this.store.dispatch(manualReconciliation());
  }
}
