export class UserSession {
  constructor(
    private accessToken: string,
    // private accessToken: string,
    // private refreshToken: string,
    // private expirationDate: Date,
    // private userData: any,
  ) {}

  get access_token() {
    return this.accessToken;
  }

  // get access_token() {
  //   return this.accessToken;
  // }

  // get refresh_token() {
  //   return this.refreshToken;
  // }

  // get expiration_date() {
  //   return this.expirationDate;
  // }

  // get user_data() {
  //   return this.userData;
  // }
}

