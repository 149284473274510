<div class="grid">
	<div class="row p-3">
		<div class="col-md-12">
			<h3>Select Filter</h3>
		</div>
		<div class="col-md-12">
			<div class="list-group">
				<label class="list-group-item capitalize" *ngFor="let item of filter">
					<input class="form-check-input me-1" type="radio" name="exampleRadios" id="{{item?.value}}" [value]="item" (change)="onChange(item)" [(ngModel)]="currentFilter">
					{{ item?.code }}
				</label>
			</div>
		</div>
	</div>

	<div class="row">
		<div class="col-md-12 d-flex justify-content-center">
			<button class="btn btn-primary" mat-raised-button color="primary" (click)="proceed()">Proceed</button>
			<button mat-raised-button color="danger" class="cancel" (click)="cancel()">Cancel</button>
		</div>
	</div>
</div>