import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {

  message!: any;

  constructor(
    private bsModalService: BsModalService,
    private modalService: ModalService
  ) { }

  ngOnInit(): void {

  }

  dismiss(){
    this.modalService.hideSuccessModal();
  }
}
