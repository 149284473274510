import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromShared from '../reducers/shared.reducer';

export const selectState = createFeatureSelector<fromShared.State>(
  fromShared.featureKey
);

export const getLoading = createSelector(selectState, (state) => state.showLoading);
export const getErrorMessage = createSelector(selectState, (state) => state.errorMessage);
export const getWelcomeContent = createSelector(selectState, (state) => state.welcomeContent);
export const getFCMToken = createSelector(selectState, (state) => state.fcmToken);
export const getUnreadNotification = createSelector(selectState, (state) => state.unreadNotifications);
export const eventList = createSelector(selectState, (state) => state.getUserEvents);
export const dashboardOrder = createSelector(selectState, (state) => state.dashboardOrder);
