import { Injectable } from '@angular/core';
// import { Preferences } from '@capacitor/preferences';

@Injectable({
  providedIn: 'root',
})
export class OfflineStorageService {
  constructor() {}

  async setObject(key: string, value: any) {
    localStorage.setItem(key, value);
  }

  async getObject(key: string): Promise<any> {
    return localStorage.getItem(key);
  }

  async removeItem(key: string) {
    await localStorage.removeItem(key);
  }

  async clear() {
    await localStorage.clear();
  }
}
