import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';
import { StoreModule } from '@ngrx/store';
import { reducers } from './store/reducers';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { effects } from './store/effects';
import { CustomSerializer } from './store/router/custom-route-serializer';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { ComponentLoaderFactory } from 'ngx-bootstrap/component-loader';
import { PositioningService } from 'ngx-bootstrap/positioning';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { PlatformModule } from '@angular/cdk/platform';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor, TokenInterceptor } from './interceptor/token.interceptor';

// Page Content
import { PageContentComponent } from './layout/page-content/page-content.component';
import { MaterialModule } from './material/material.module';

// Home
import { HomeLayoutComponent } from './layout/home/home-layout/home-layout.component';
import { HomeFooterComponent } from './layout/home/home-footer/home-footer.component';
import { HomeSideNavComponent } from './layout/home/home-side-nav/home-side-nav.component';
import { HomeSideNavTogglerComponent } from './layout/home/home-side-nav-toggler/home-side-nav-toggler.component';
import { HomeTopNavComponent } from './layout/home/home-top-nav/home-top-nav.component';
import { LOAD_WASM, NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import {WebcamModule} from 'ngx-webcam';
// import { NgChartsModule } from 'ng2-charts';

LOAD_WASM().subscribe();

@NgModule({
  declarations: [
    AppComponent,
    PageContentComponent,
    HomeLayoutComponent,
    HomeFooterComponent,
    HomeSideNavComponent,
    HomeSideNavTogglerComponent,
    HomeTopNavComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    StoreModule.forRoot(reducers),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EffectsModule.forRoot(effects),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomSerializer
    }),
    ModalModule,
    NgbModule,
    PlatformModule,
    MaterialModule,
    NgxScannerQrcodeModule,
    WebcamModule,
    // NgChartsModule
  ],
  providers: [
    BsModalService,
    ComponentLoaderFactory, 
    PositioningService, 
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }  
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
