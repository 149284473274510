/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';

export enum ActionTypes {
    GET_UPLOAD_URL = '[Image] Get Upload Url',
    GET_UPLOAD_URL_SUCCESS = '[Image] Get Upload Url Success',
    GET_UPLOAD_URL_FAILED = '[Image] Get Upload Url Failed',
    UPLOAD_PROFILE_IMAGE = '[Image] Upload Profile Photo',
    UPLOAD_PROFILE_IMAGE_SUCCESS = '[Image] Upload Profile Photo Success',
    UPLOAD_PROFILE_IMAGE_FAILED = '[Image] Upload Profile Photo Failed',
}

export const getUploadUrl = createAction(
    ActionTypes.GET_UPLOAD_URL
);

export const getUploadUrlSuccess = createAction(
    ActionTypes.GET_UPLOAD_URL_SUCCESS,
    props<{ data: any }>()
);

export const getUploadUrlFailed = createAction(
    ActionTypes.GET_UPLOAD_URL_FAILED,
    props<{ data: any }>()
);

export const uploadProfileImage = createAction(
    ActionTypes.UPLOAD_PROFILE_IMAGE,
    props<{ data: any }>()
);

export const uploadProfileImageSuccess = createAction(
    ActionTypes.UPLOAD_PROFILE_IMAGE_SUCCESS,
    props<{ data: any }>()
);

export const uploadProfileImageFailed = createAction(
    ActionTypes.UPLOAD_PROFILE_IMAGE_FAILED,
    props<{ data: any }>()
);