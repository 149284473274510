import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingSpinnerComponent } from 'src/app/shared/modals/loading-spinner/loading-spinner.component';
import { QrCodeDirective } from 'src/app/directives/qr-code.directive';

@NgModule({
  declarations: [
    QrCodeDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    QrCodeDirective,
  ]
})
export class PagesModule { }
