/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';

export enum ActionTypes {
  LOGIN = '[Auth] Login',
  SOCIAL_LOGIN = '[Auth] Social Login',
  SOCIAL_LOGIN_V2 = '[Auth] Social Login V2',
  AUTO_LOGIN= '[Auth] Auto Login',
  LOGIN_SUCCESS = '[Auth] Login Success',
  LOGIN_FAILED = '[Auth] Login Failed',
  SIGNUP = '[Auth] Signup',
  SIGNUP_SUCCESS = '[Auth] Signup Success',
  SIGNUP_FAILED = '[Auth] Signup Failed',
  VERIFY_SIGNUP = '[Auth] Verify Signup',
  VERIFY_SIGNUP_SUCCESS = '[Auth] Verify Signup Success',
  VERIFY_SIGNUP_FAILED = '[Auth] Verify Signup Failed',
  SEND_OTP = '[Auth] Sent OTP',
  SEND_OTP_SUCCESS = '[Auth] Sent OTP Success',
  SEND_OTP_FAILED = '[Auth] Sent OTP Failed',
  FORGOT_PASSWORD = '[Auth] Forgot Password',
  FORGOT_PASSWORD_SUCCESS = '[Auth] Forgot Password Success',
  FORGOT_PASSWORD_FAILED= '[Auth] Forgot Password Failed',
  VERIFY_FORGOT_PASSWORD = '[Auth] Verify Forgot Password',
  VERIFY_FORGOT_PASSWORD_SUCCESS = '[Auth] Verify Forgot Password Success',
  VERIFY_FORGOT_PASSWORD_FAILED = '[Auth] Verify Forgot Password Failed',
  CONFIRM_PASSWORD = '[Auth] Confirm Password',
  CONFIRM_PASSWORD_SUCCESS = '[Auth] Confirm Password Success',
  CONFIRM_PASSWORD_FAILED = '[Auth] Confirm Password Failed',
  AUTO_LOGOUT= '[Auth] Auto Logout',
  RESEND_FORGOT_OTP = '[Auth] Resend Forgot OTP',
  RESEND_FORGOT_OTP_SUCCESS = '[Auth] Resend Forgot OTP Success',
  RESEND_FORGOT_OTP_FAILED = '[Auth] Resend Forgot OTP Failed',
  DEVICE_LOGIN = '[Auth] Device Login',
  DEVICE_LOGIN_SUCCESS = '[Auth] Device Login Success',

}

export const logIn = createAction(
  ActionTypes.LOGIN,
  props<{ data: any }>()
);

export const socialLogin = createAction(
  ActionTypes.SOCIAL_LOGIN,
  props<{ providerId: string; providerToken: string,  providerName: string, transactionType: string}>()
);

export const socialLoginV2 = createAction(
  ActionTypes.SOCIAL_LOGIN_V2,
  props<{ data: any, socialType: any}>()
);

export const autoLogin = createAction(
  ActionTypes.AUTO_LOGIN
);

export const logInSuccess = createAction(
  ActionTypes.LOGIN_SUCCESS,
  props<{ data: any, redirect: Boolean }>()
);

export const loginFailed = createAction(
  ActionTypes.LOGIN_FAILED,
  props<{ data: any, payload: any }>()
);

export const signUp = createAction(
  ActionTypes.SIGNUP,
  props<{ 
    data: any
  }>()
);

export const signUpSuccess = createAction(
  ActionTypes.SIGNUP_SUCCESS,
  props<{ data: any }>()
);
export const signUpFailed = createAction(
  ActionTypes.SIGNUP_FAILED,
  props<{ data: any }>()
);

export const verifySignUp = createAction(
  ActionTypes.VERIFY_SIGNUP,
  props<{ data: any }>()
);

export const verifySignUpSuccess = createAction(
  ActionTypes.VERIFY_SIGNUP_SUCCESS,
  props<{ data: any }>()
);
export const verifySignUpFailed = createAction(
  ActionTypes.VERIFY_SIGNUP_FAILED,
  props<{ data: any }>()
);

export const sendOtp = createAction(
  ActionTypes.SEND_OTP,
  props<{ data: any }>()
);

export const sendOtpSuccess = createAction(
  ActionTypes.SEND_OTP_SUCCESS,
  props<{ data: any }>()
);

export const sendOtpFailed = createAction(
  ActionTypes.SEND_OTP_FAILED,
  props<{ data: any }>()
);

export const forgotPassword = createAction(
  ActionTypes.FORGOT_PASSWORD,
  props<{ username: string; }>()
);

export const forgotSuccess = createAction(
  ActionTypes.FORGOT_PASSWORD_SUCCESS,
  props<{ data: any }>()
);

export const forgotFailed = createAction(
  ActionTypes.FORGOT_PASSWORD_FAILED,
  props<{ data: any }>()
);

export const verifyForgotPassword = createAction(
  ActionTypes.VERIFY_FORGOT_PASSWORD,
  props<{ data: any }>()
);

export const verifyForgotPasswordSuccess = createAction(
  ActionTypes.VERIFY_FORGOT_PASSWORD_SUCCESS,
  props<{ tempUserSession: any }>()
);
export const verifyForgotPasswordFailed = createAction(
  ActionTypes.VERIFY_FORGOT_PASSWORD_FAILED,
  props<{ data: any }>()
);
export const confirmPassword = createAction(
  ActionTypes.CONFIRM_PASSWORD,
  props<{ data: any; }>()
);

export const confirmSuccess = createAction(
  ActionTypes.CONFIRM_PASSWORD_SUCCESS,
  props<{ data: any }>()
);

export const confirmFailed = createAction(
  ActionTypes.CONFIRM_PASSWORD_FAILED,
  props<{ data: any }>()
);

export const autoLogout = createAction(
  ActionTypes.AUTO_LOGOUT
);

export const resendForgotOtp = createAction(
  ActionTypes.RESEND_FORGOT_OTP,
  props<{ username: any; }>()
);

export const resendForgotOtpSuccess = createAction(
  ActionTypes.RESEND_FORGOT_OTP_SUCCESS,
  props<{ data: any }>()
);

export const resendForgotOtpFailed = createAction(
  ActionTypes.RESEND_FORGOT_OTP_FAILED,
  props<{ data: any }>()
);

export const deviceLogin = createAction(
  ActionTypes.DEVICE_LOGIN,
  props<{ data: any }>()
);

export const deviceLoginSuccess = createAction(
  ActionTypes.DEVICE_LOGIN_SUCCESS,
  props<{ data: any }>()
);